import BaseButton from 'components/base/BaseButton';
import BaseInput, { BaseInputGroup } from 'components/base/BaseInput';
import validateEmail from 'helpers/validateEmail';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  AccountBoxRounded,
  EmailRounded,
  LockRounded
} from '@mui/icons-material';
import { Box, Checkbox, Grow, InputAdornment } from '@mui/material';

import { signUpUser } from 'redux/slices/userSlice';

import styles from './Shared.module.scss';

import logoPeekdata from 'static/images/logo_w.svg';

export default function SignUpScreen() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirmation: ''
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice.user);

  useEffect(() => {
    if (user.data) {
      navigate('/dashboard');
    }
  }, [user.data]); // eslint-disable-line

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name === '') {
      setValidationErrors((prevState) => ({
        ...prevState,
        name: 'should be present'
      }));
    } else {
      setValidationErrors((prevState) => ({ ...prevState, name: '' }));
    }

    if (email === '') {
      setValidationErrors((prevState) => ({
        ...prevState,
        email: 'should be present'
      }));
    } else if (!validateEmail(email)) {
      setValidationErrors((prevState) => ({
        ...prevState,
        email: 'should be valid'
      }));
    } else {
      setValidationErrors((prevState) => ({ ...prevState, email: '' }));
    }

    if (password === '') {
      setValidationErrors((prevState) => ({
        ...prevState,
        password: 'should be present'
      }));
    } else {
      setValidationErrors((prevState) => ({ ...prevState, password: '' }));
    }

    if (password !== passwordConfirmation || passwordConfirmation === '') {
      setValidationErrors((prevState) => ({
        ...prevState,
        passwordConfirmation: 'should match password'
      }));

      return;
    } else {
      setValidationErrors((prevState) => ({
        ...prevState,
        passwordConfirmation: ''
      }));
    }

    dispatch(signUpUser({ email, password, name }));
  };

  return (
    <Grow in={true}>
      <Box className={styles.wrapper}>
        <Box className={styles.logoWrapper}>
          <img className={styles.logo} src={logoPeekdata} alt="logo" />
          <span>beta</span>
        </Box>
        <Box className={styles.card}>
          <Box className={styles.cardTitle}>Sign up</Box>
          <form onSubmit={handleSubmit}>
            <BaseInputGroup>
              <BaseInput
                placeholder="Full name"
                type="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountBoxRounded
                      htmlColor={
                        validationErrors.name === '' ? '#3F3E3E' : '#E13434'
                      }
                    />
                  </InputAdornment>
                }
                validationError={validationErrors.name}
              />
              <BaseInput
                className={styles.input}
                disableUnderline
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <EmailRounded
                      htmlColor={
                        validationErrors.email === '' ? '#3F3E3E' : '#E13434'
                      }
                    />
                  </InputAdornment>
                }
                validationError={validationErrors.email}
              />
              <BaseInput
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <LockRounded
                      htmlColor={
                        validationErrors.password === '' ? '#3F3E3E' : '#E13434'
                      }
                    />
                  </InputAdornment>
                }
                validationError={validationErrors.password}
              />
              <BaseInput
                placeholder="Password confirmation"
                type="password"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <LockRounded
                      htmlColor={
                        validationErrors.passwordConfirmation === ''
                          ? '#3F3E3E'
                          : '#E13434'
                      }
                    />
                  </InputAdornment>
                }
                validationError={validationErrors.passwordConfirmation}
              />
            </BaseInputGroup>
            <Box
              className={styles.checkboxWrapper}
              style={{ alignItems: 'center' }}
            >
              <Checkbox
                id="js-terms-checkbox"
                style={{ color: '#343434', marginLeft: -8 }}
              />
              <label htmlFor="js-terms-checkbox" style={{ fontSize: 14 }}>
                I acknowledge this is a beta version of the app
                {/* I agree to the{' '}
                <a style={{ color: '#E13434' }} href="/terms_of_service">
                  Terms
                </a>{' '}
                and{' '}
                <a style={{ color: '#E13434' }} href="/privacy_policy">
                  Privacy Policy
                </a> */}
              </label>
            </Box>
            <BaseButton
              type="submit"
              title="Sign up"
              loading={user.loading}
              style={{ marginTop: 15 }}
            />
          </form>
          <Box className={styles.cardFooter}>
            <Box>Already have an account?</Box>
            <Link to="/sign_in" className={styles.cardFooterLink}>
              Sign in
            </Link>
          </Box>
        </Box>
      </Box>
    </Grow>
  );
}
