import ClusterForm from '../clusters/ClusterForm';
// import EnvironmentCreatePage from '../environments/EnvironmentForm';
import OrganizationForm from '../organizations/OrganizationForm';
import DataModelForm from 'features/dataModels/DataModelForm';

import React from 'react';
import { useSelector } from 'react-redux';

export default function DashboardModalManager() {
  const modal = useSelector((state) => state.uiSlice.modal);

  return (
    <>
      {modal === 'organization-create' && <OrganizationForm />}
      {modal === 'cluster-create' && <ClusterForm />}
      {/* {modal === 'environment-create' && <EnvironmentCreatePage />} */}
      {modal === 'dataModel-create' && <DataModelForm />}
      {/* {modal === 'delete-entity' && <DeletePage />} */}
    </>
  );
  // return <Outlet />;
}
