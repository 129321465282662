import { createSlice } from '@reduxjs/toolkit';

const rootSlice = createSlice({
  name: 'root',
  initialState: {
    index: 0
  },
  reducers: {}
});

export default rootSlice.reducer;
