import React from 'react';

import { Box, Button, CircularProgress } from '@mui/material';

import styles from './BaseButton.module.scss';

export default function BaseButton(props) {
  return (
    <Button
      {...props}
      className={`${styles.button} ${props.className}`}
      disabled={props.loading || props.disabled}
    >
      <Box
        className={styles.buttonProgressWrapper}
        style={{ opacity: props.loading ? 1 : 0 }}
      >
        <CircularProgress thickness={7} size={30} style={{ color: '#FFF' }} />
      </Box>
      {props.title}
    </Button>
  );
}
