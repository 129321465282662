import BaseInput, { BaseInputGroup } from 'components/base/BaseInput';

import React from 'react';
import { useDispatch } from 'react-redux';

import { BusinessRounded } from '@mui/icons-material';
import { Box, Button, Grow, InputAdornment } from '@mui/material';

import { createOrganization } from 'redux/slices/organizationSlice';

import styles from './Shared.module.scss';

// import logoPeekdata from 'static/images/logo_w.svg';

export default function SignUpOrganizationScreen() {
  const dispatch = useDispatch();
  const [name, setName] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createOrganization({ name }));
  };

  return (
    <Grow in={true}>
      <Box className={styles.wrapper}>
        {/* <img className={styles.logo} src={logoPeekdata} alt="logo" /> */}
        <Box className={styles.card}>
          <Box className={styles.cardTitle} style={{ marginBottom: 20 }}>
            Organization
          </Box>
          <Box style={{ marginBottom: 20 }}>
            Create an organization to manage your clusters and users.
          </Box>
          <form onSubmit={handleSubmit}>
            <BaseInputGroup>
              <BaseInput
                placeholder="Acme Inc."
                autoComplete="off"
                value={name}
                onChange={(e) => setName(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <BusinessRounded htmlColor="#3F3E3E" />
                  </InputAdornment>
                }
              />
            </BaseInputGroup>
            <Button
              type="submit"
              className={styles.button}
              style={{ marginTop: 30 }}
            >
              Create
            </Button>
          </form>
        </Box>
      </Box>
    </Grow>
  );
}
