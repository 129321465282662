import React from 'react';

import { Box } from '@mui/material';

import styles from './LoadingBox.module.scss';

export default function LoadingBox(props) {
  return (
    <Box className={styles.wrapper} style={props.style}>
      <Box
        className={styles.background}
        style={{
          opacity: props.loading ?? true ? 1 : 0,
          pointerEvents: props.loading ?? true ? 'all' : 'none'
        }}
      >
        <Box className={styles.gradient} />
      </Box>
      {props.children}
    </Box>
  );
}
