import BaseInput from 'components/base/BaseInput';
import BaseSelect from 'components/base/BaseSelect';
import { DATABASE_DRIVERS } from 'shared/Constants';

import React from 'react';

import { Delete } from '@mui/icons-material';
import { Box } from '@mui/material';

import styles from './DataModelConnectionRow.module.scss';
import baseStyles from 'components/base/BaseStyles.module.scss';

export default function DataModelConnectionRow(props) {
  return (
    <Box key={props.connection.id} className={styles.wrapper}>
      {props.shouldDelete && (
        <Box className={baseStyles.deleteOverlay}>
          This connection is staged for deletion.&nbsp;
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.onDeleteUndo(props.index);
            }}
          >
            Undo
          </a>
        </Box>
      )}
      <Box className={baseStyles.defaultFlex} style={{ marginBottom: 10 }}>
        <BaseInput
          wrapperStyle={{ flex: 1, paddingRight: 5 }}
          className={baseStyles.titleInput}
          placeholder="Connection name"
          value={props.connection.name}
          onChange={(e) =>
            props.onConnectionChange(props.index, 'name', e.target.value)
          }
        />
        <Box
          className={`${baseStyles.buttonSm} ${baseStyles.remove}`}
          onClick={() => props.onRemove(props.index)}
        >
          <Delete style={{ fontSize: 20 }} />
        </Box>
      </Box>
      {['username', 'password', 'url'].map((key) => {
        return (
          <Box key={`${props.connection.id}-${key}`}>
            <BaseInput
              value={props.connection[key]}
              placeholder={key === 'url' ? 'Database URL' : key}
              dynamicPlaceholder={true}
              dynamicPlaceholderClassName={styles.dynamicPlaceholder}
              type={key === 'password' ? 'password' : 'text'}
              style={{ fontSize: 14, height: 37 }}
              onChange={(e) =>
                props.onConnectionChange(props.index, key, e.target.value)
              }
            />
          </Box>
        );
      })}
      <BaseSelect
        placeholder="Driver"
        autoComplete="off"
        value={props.connection.driver}
        items={DATABASE_DRIVERS}
        style={{ fontSize: 14, height: 37 }}
        onChange={(e) =>
          props.onConnectionChange(props.index, 'driver', e.target.value)
        }
        dynamicPlaceholder
        dynamicPlaceholderClassName={styles.dynamicPlaceholder}
      />
    </Box>
  );
}
