import BodyStyle from '../shared/BodyStyle';

import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import styles from './AuthLayout.module.scss';

import iconArrowBack from 'static/icons/i_arrow_back.svg';

export default function AuthLayout(props) {
  return (
    <Box>
      <BodyStyle background="#910c2e" />
      <header className={styles.header}>
        <Link className={styles.headerBackButton} to="https://peekdata.io">
          <img src={iconArrowBack} width="9" alt="Back" />
          Back to main
        </Link>
      </header>
      <Box className={styles.contentWrapper}>
        <Outlet />
      </Box>
      <Box className={styles.footer}>© 2023, Peekdata.io</Box>
    </Box>
  );
}
