const DATABASE_CHOICES = [
  // { name: 'Database type', value: '' },
  { name: 'PostgreSQL', value: 'postgresql' },
  { name: 'MySQL', value: 'mysql' },
  { name: 'MSSQL', value: 'mssql' },
  { name: 'Oracle', value: 'oracle' }
];

const SCHEMA_LOCATION_TYPE = [
  // { name: 'Schema location type', value: '' },
  { name: 'File system', value: 'fs' },
  { name: 'Embedded', value: 'em' },
  { name: 'Amazon S3', value: 's3' },
  { name: 'Git', value: 'git' }
];

const CLUSTER_TYPE_CHOICES = [
  // { name: 'Type', value: '' },
  { name: 'Dedicated', value: 'DD' },
  { name: 'Shared', value: 'SH' }
];

const CLUSTER_STATE_CHOICES = [
  // { name: 'State', value: '' },
  { name: 'New', value: 'NW', availableStates: ['CF'] },
  { name: 'Configured', value: 'CF' },
  { name: 'Creating', value: 'CR' },
  { name: 'Starting', value: 'SR' },
  { name: 'Stopping', value: 'PG' },
  { name: 'Terminating', value: 'TM' },
  { name: 'Deployment failed', value: 'DF' },
  { name: 'Running', value: 'RU' },
  { name: 'Stopped', value: 'ST' },
  { name: 'Deleted', value: 'DL' }
];

const DATABASE_DRIVERS = [
  {
    name: 'PostgreSQL',
    value: 'org.postgresql.Driver',
    className: 'io.peekdata.datagateway.render.postgresql.PostgreSQLRender'
  },
  {
    name: 'Oracle',
    value: 'oracle.jdbc.driver.OracleDriver',
    className: 'io.peekdata.datagateway.render.oracle.OracleRender'
  },
  {
    name: 'Snowflake',
    value: 'net.snowflake.client.jdbc.SnowflakeDriver',
    className: 'io.peekdata.datagateway.render.snowflake.SnowflakeRender'
  },
  {
    name: 'Apache Hive',
    value: 'com.amazon.hive.jdbc.HS2DataSource',
    className: 'io.peekdata.datagateway.render.hive.HiveRender'
  },
  {
    name: 'Amazon Athena',
    value: 'com.simba.athena.jdbc.Driver',
    className: 'io.peekdata.datagateway.render.athena.AthenaRender'
  },
  {
    name: 'Amazon Redshift',
    value: 'com.amazon.redshift.jdbc42.Driver',
    className: 'io.peekdata.datagateway.render.redshift.RedshiftRender'
  },
  {
    name: 'Vertica',
    value: 'com.vertica.jdbc.Driver',
    className: 'io.peekdata.datagateway.render.vertica.VerticaRender'
  },
  {
    name: 'MySQL',
    value: 'org.mariadb.jdbc.Driver',
    className: 'io.peekdata.datagateway.render.mysql.MySQLRender'
  },
  {
    name: 'Microsoft SQL Server',
    value: 'com.microsoft.sqlserver.jdbc.SQLServerDriver',
    className: 'io.peekdata.datagateway.render.sqlserver.SQLServerRender'
  },
  {
    name: 'Firebird',
    value: 'org.firebirdsql.jdbc.FBDriver',
    className: 'io.peekdata.datagateway.render.firebird.FirebirdRender'
  },
  {
    name: 'MongoDB',
    value: 'com.mysql.jdbc.Driver',
    className: 'io.peekdata.datagateway.render.mongo.MongoRender'
  },
  {
    name: 'SQL Lite',
    value: 'org.sqlite.JDBC',
    className: 'io.peekdata.datagateway.render.sqlite.SQLiteRender'
  },
  {
    name: 'SQL Anywhere',
    value: 'sap.jdbc4.sqlanywhere.IDriver',
    className: 'io.peekdata.datagateway.render.sqlany.SQLAnyRender'
  }
];

export {
  DATABASE_CHOICES,
  SCHEMA_LOCATION_TYPE,
  CLUSTER_TYPE_CHOICES,
  CLUSTER_STATE_CHOICES,
  DATABASE_DRIVERS
};
