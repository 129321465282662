import { addSnackbarToQueue } from './uiSlice';
import Cookies from 'js-cookie';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const listClusters = createAsyncThunk(
  'clusterReducer/listClusters',
  async ({ environmentId, organizationId }, thunkAPI) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/clusters/`;
    if (environmentId) {
      url += `?environment_id=${environmentId}`;
    }

    if (organizationId) {
      if (url.includes('?') === false) {
        url += '?';
      }

      url += `organization_id=${organizationId}`;
    }

    try {
      return await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const createCluster = createAsyncThunk(
  'clusterReducer/createCluster',
  async (
    {
      name,
      description,
      type,
      size,
      environmentId,
      csvSettings,
      engineSettings,
      dataModelIds
    },
    thunkAPI
  ) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/clusters/`;
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          name,
          description,
          environment_id: environmentId,
          cluster_size: size,
          cluster_type: type,
          csv_settings: csvSettings,
          engine_services: engineSettings,
          datamodels: dataModelIds
        })
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Created cluster.',
              type: 'success'
            })
          );

          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const updateCluster = createAsyncThunk(
  'clusterReducer/updateCluster',
  async (
    { id, name, description, type, size, csvSettings, engineSettings, dataModelIds },
    thunkAPI
  ) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/cluster/${id}`;

    try {
      return await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          name,
          description,
          type,
          size,
          csv_settings: csvSettings,
          engine_services: engineSettings,
          datamodels: dataModelIds
        })
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Updated cluster.',
              type: 'success'
            })
          );

          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const fetchClusterStatus = createAsyncThunk(
  'clusterReducer/fetchClusterStatus',
  async ({ id }, thunkAPI) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/cluster/action/${id}`;
    try {
      return await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const updateClusterStatus = createAsyncThunk(
  'clusterReducer/updateClusterStatus',
  async ({ id, action }, thunkAPI) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/cluster/action/${id}`;
    try {
      return await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          action
        })
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Updated cluster status.',
              type: 'success'
            })
          );

          return {};
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const deleteCluster = createAsyncThunk(
  'clusterReducer/deleteCluster',
  async ({ id }, thunkAPI) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/cluster/${id}`;
    try {
      return await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Deleted cluster.',
              type: 'success'
            })
          );

          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

const clusterSlice = createSlice({
  name: 'clusterReducer',
  initialState: {
    cluster: {},
    clusters: {
      loading: false,
      data: [],
      error: null
    }
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createCluster.pending, (state) => {
        state.cluster.loading = true;
        state.cluster.data = null;
        state.cluster.error = null;
      })
      .addCase(createCluster.fulfilled, (state, action) => {
        state.cluster.loading = false;
        state.cluster.data = action.payload;
        state.cluster.error = null;
      })
      .addCase(createCluster.rejected, (state, action) => {
        state.cluster.loading = false;
        state.cluster.data = null;
        state.cluster.error = action.payload;
      })
      .addCase(updateCluster.pending, (state) => {
        state.cluster.loading = true;
        state.cluster.data = null;
        state.cluster.error = null;
      })
      .addCase(updateCluster.fulfilled, (state, action) => {
        state.cluster.loading = false;
        state.cluster.data = action.payload;
        state.cluster.error = null;
      })
      .addCase(updateCluster.rejected, (state, action) => {
        state.cluster.loading = false;
        state.cluster.data = null;
        state.cluster.error = action.payload;
      })
      .addCase(deleteCluster.pending, (state) => {
        state.cluster.loading = true;
        state.cluster.error = null;
      })
      .addCase(deleteCluster.fulfilled, (state, action) => {
        state.cluster.loading = false;
        state.cluster.error = null;
      })
      .addCase(deleteCluster.rejected, (state, action) => {
        state.cluster.loading = false;
        state.cluster.error = action.payload;
      })
      .addCase(listClusters.pending, (state) => {
        state.clusters.loading = true;
        state.clusters.data = null;
        state.clusters.error = null;
      })
      .addCase(listClusters.fulfilled, (state, action) => {
        state.clusters.loading = false;
        state.clusters.data = action.payload;
        state.clusters.error = null;
      })
      .addCase(listClusters.rejected, (state, action) => {
        state.clusters.loading = false;
        state.clusters.data = null;
        state.clusters.error = action.payload;
      })
      // .addCase(fetchClusterStatus.pending, (state) => {
      //   // state.cluster.loading = true;
      //   // state.cluster.data = null;
      //   // state.cluster.error = null;
      // })
      .addCase(fetchClusterStatus.fulfilled, (state, action) => {
        state.clusters.data = state.clusters.data?.map((cluster) => {
          if (cluster.id === action.meta.arg.id) {
            cluster.cluster_state = action.payload?.state;
            cluster.cluster_actions = action.payload?.actions;
          }

          return cluster;
        });
      })
      .addCase(updateClusterStatus.fulfilled, (state, action) => {});
    // .addCase(fetchClusterStatus.rejected, (state, action) => {
    //   // state.cluster.loading = false;
    //   // state.cluster.data = null;
    //   // state.cluster.error = action.payload;
    // })
  }
});

export default clusterSlice.reducer;
