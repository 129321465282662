import BaseButton from 'components/base/BaseButton';
import BaseInput, { BaseInputGroup } from 'components/base/BaseInput';

import React from 'react';
import { Link } from 'react-router-dom';

import { EmailRounded } from '@mui/icons-material';
import { Box, Grow, InputAdornment } from '@mui/material';

import styles from './Shared.module.scss';

import logoPeekdata from 'static/images/logo_w.svg';

export default function ForgotPasswordScreen() {
  return (
    <Grow in={true}>
      <Box className={styles.wrapper}>
        <Box className={styles.logoWrapper}>
          <img className={styles.logo} src={logoPeekdata} alt="logo" />
          <span>beta</span>
        </Box>
        <Box className={styles.card}>
          <Box className={styles.cardTitle}>Reset password</Box>
          <BaseInputGroup>
            <BaseInput
              placeholder="Email"
              type="email"
              startAdornment={
                <InputAdornment position="start">
                  <EmailRounded htmlColor="#3F3E3E" />
                </InputAdornment>
              }
            ></BaseInput>
          </BaseInputGroup>
          <BaseButton title="Send reset instructions" />
          <Box className={styles.cardFooter}>
            <Link to="/sign_in" className={styles.cardFooterLink}>
              Back to sign in
            </Link>
          </Box>
        </Box>
      </Box>
    </Grow>
  );
}
