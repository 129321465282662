export const pattern =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export default function validateEmail(email) {
  if ([undefined, null, ''].includes(email)) {
    return false;
  }

  return email.match(pattern) !== null;
}
