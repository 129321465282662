import DeletePage from '../shared/DeletePage';
import LoadingBox from '../shared/LoadingBox';
import ClusterCard from './ClusterCard';
import ClusterForm from './ClusterForm';
import ClusterGetStartedCard from './ClusterGetStartedCard';
import BaseInput from 'components/base/BaseInput';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AddCircle } from '@mui/icons-material';
import { Box, Button, Grid, MenuItem, Select } from '@mui/material';

import { deleteCluster, listClusters } from 'redux/slices/clusterSlice';
import {
  listEnvironments,
  setCurrentEnvironment
} from 'redux/slices/environmentSlice';

// import { displayModal } from 'redux/slices/uiSlice';
import styles from './ClustersIndexPage.module.scss';

import iconArrowDown from 'static/icons/i_sidebar_arrow_down.svg';

export default function ClustersIndexPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [editModalData, setEditModalData] = useState(null);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const clusters = useSelector((state) => state.clusterSlice.clusters);
  const environments = useSelector(
    (state) => state.environmentSlice.environments
  );
  const currentEnvironment = useSelector(
    (state) => state.environmentSlice.currentEnvironment
  );
  const currentOrganizationId = useSelector(
    (state) => state.organizationSlice.currentOrganizationId
  );

  useEffect(() => {
    if (clusters.loading) {
      setLoadingCounter(loadingCounter + 1);
    }
  }, [clusters.loading]); // eslint-disable-line

  useEffect(() => {
    if (currentOrganizationId == null) {
      return;
    }

    dispatch(listEnvironments({ organizationId: currentOrganizationId }));
  }, [currentOrganizationId]); // eslint-disable-line

  useEffect(() => {
    if (currentEnvironment == null) {
      return;
    }

    dispatch(listClusters({ environmentId: currentEnvironment.id }));
  }, [currentEnvironment]); // eslint-disable-line

  useEffect(() => {
    if (currentEnvironment == null && environments.data?.length > 0) {
      dispatch(setCurrentEnvironment(environments.data[0]));
    } else {
      dispatch(setCurrentEnvironment(null));
    }
  }, [environments]); // eslint-disable-line

  const handleDeleteAction = () => {
    dispatch(deleteCluster({ id: deleteModalData.entityId })).then(() => {
      dispatch(listClusters({ environmentId: currentEnvironment.id }));
    });
    setDeleteModalData(null);
  };

  return (
    <>
      <Box className={styles.header}>
        <Box className={styles.title}>Clusters</Box>
        <Box style={{ marginLeft: 'auto' }}>
          {/* <span style={{ marginRight: 8 }}>Environment</span> */}
          {currentOrganizationId && (
            <LoadingBox
              loading={environments.loading}
              style={{ borderRadius: 10 }}
            >
              <Select
                className={styles.input}
                disableUnderline
                displayEmpty
                value={currentEnvironment?.id ?? null}
                input={
                  <BaseInput
                    style={{ height: 44, background: '#FFF', minWidth: 218 }}
                  />
                }
                IconComponent={() => (
                  <img src={iconArrowDown} alt="arrow down" />
                )}
                onChange={(e) => {
                  if (e.target.value === 'create') {
                    navigate('/dashboard/environments/new');
                  } else {
                    dispatch(
                      setCurrentEnvironment(
                        environments.data.find(
                          (env) => env.id === e.target.value
                        )
                      )
                    );
                  }
                }}
              >
                <MenuItem
                  className={styles.inputMenuItem}
                  value={null}
                  disabled
                >
                  Select an environment
                </MenuItem>
                {environments.data?.map((item) => (
                  <MenuItem
                    className={styles.inputMenuItem}
                    value={item.id}
                    key={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
                <MenuItem className={styles.inputMenuItem} value="create">
                  <AddCircle style={{ marginRight: 8 }} />
                  <strong>Create new</strong>
                </MenuItem>
              </Select>
            </LoadingBox>
          )}
        </Box>
        <Button
          className={styles.button}
          state={{ background: location }}
          onClick={() => navigate('/dashboard/clusters/new')}
        >
          <AddCircle style={{ fontSize: 28 }} />
          <span>Create new</span>
        </Button>
      </Box>
      <Grid container spacing={2} mt={2}>
        {(
          clusters.data ?? [
            { data: { name: 'dummy', id: '1' } },
            { data: { name: 'dummy', id: '2' } },
            { data: { name: 'dummy', id: '3' } },
            { data: { name: 'dummy', id: '4' } },
            { data: { name: 'dummy', id: '5' } },
            { data: { name: 'dummy', id: '6' } }
          ]
        ).map((clusterData, index) => (
          <Grid item xs={6} md={4} lg={4} key={`cluster-card-${index}`}>
            <LoadingBox style={{ borderRadius: 16 }} loading={clusters.loading}>
              <ClusterCard
                data={clusterData}
                onEdit={(editModalData) => setEditModalData(editModalData)}
                onDelete={(deleteModalData) =>
                  setDeleteModalData(deleteModalData)
                }
              />
            </LoadingBox>
          </Grid>
        ))}
        {clusters.loading === false && (clusters.data ?? []).length === 0 && (
          <Grid item xs={12} md={12} lg={12}>
            <ClusterGetStartedCard
              isOrganizationPresent={currentOrganizationId != null}
              isEnvironmentPresent={currentEnvironment != null}
              isClusterPresent={clusters.data?.length > 0}
            />
          </Grid>
        )}
      </Grid>
      {deleteModalData && (
        <DeletePage
          {...deleteModalData}
          onCancel={() => setDeleteModalData(null)}
          onAction={handleDeleteAction}
        />
      )}
      {editModalData && (
        <ClusterForm
          data={editModalData}
          onCancel={() => setEditModalData(null)}
          onAction={() => setEditModalData(null)}
        />
      )}
    </>
  );
}
