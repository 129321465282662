import BaseButton from 'components/base/BaseButton';
import BaseInput, { BaseInputGroup } from 'components/base/BaseInput';
import validateEmail from 'helpers/validateEmail';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { EmailRounded, LockRounded } from '@mui/icons-material';
import { Box, Grow, InputAdornment } from '@mui/material';

import { signInUser } from 'redux/slices/userSlice';

import styles from './Shared.module.scss';

import logoPeekdata from 'static/images/logo_w.svg';

export default function SignInScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: ''
  });

  const dispatch = useDispatch();
  const session = useSelector((state) => state.userSlice.session);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email === '') {
      setValidationErrors((prevState) => ({
        ...prevState,
        email: 'should be present'
      }));
    } else if (!validateEmail(email)) {
      setValidationErrors((prevState) => ({
        ...prevState,
        email: 'should be valid'
      }));
    } else {
      setValidationErrors((prevState) => ({ ...prevState, email: '' }));
    }

    if (password === '') {
      setValidationErrors((prevState) => ({
        ...prevState,
        password: 'should be present'
      }));
      return;
    } else {
      setValidationErrors((prevState) => ({ ...prevState, password: '' }));
    }

    dispatch(signInUser({ email, password }));
  };

  return (
    <Grow in={true}>
      <Box className={styles.wrapper}>
        <Box className={styles.logoWrapper}>
          <img className={styles.logo} src={logoPeekdata} alt="logo" />
          <span>beta</span>
        </Box>
        <Box className={styles.card}>
          <Box className={styles.cardTitle}>Sign in</Box>
          <form onSubmit={handleSubmit}>
            <BaseInputGroup>
              <BaseInput
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <EmailRounded
                      htmlColor={
                        validationErrors.email === '' ? '#3F3E3E' : '#E13434'
                      }
                    />
                  </InputAdornment>
                }
                validationError={validationErrors.email}
              />
              <BaseInput
                className={styles.input}
                disableUnderline
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <LockRounded
                      htmlColor={
                        validationErrors.password === '' ? '#3F3E3E' : '#E13434'
                      }
                    />
                  </InputAdornment>
                }
                validationError={validationErrors.password}
              />
            </BaseInputGroup>
            <Link to="/forgot_password" className={styles.cardLink}>
              Forgot your password?
            </Link>
            <BaseButton
              type="submit"
              title="Sign in"
              loading={session.loading}
            />
          </form>
          <Box className={styles.cardFooter}>
            <Box>Don't have an account?</Box>
            <Link to="/sign_up" className={styles.cardFooterLink}>
              Sign up
            </Link>
          </Box>
        </Box>
      </Box>
    </Grow>
  );
}
