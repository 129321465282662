import { addSnackbarToQueue } from './uiSlice';
import Cookies from 'js-cookie';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const listOrganizations = createAsyncThunk(
  'organizationReducer/listOrganizations',
  async (_, thunkAPI) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/organizations/`;
    try {
      return await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const createOrganization = createAsyncThunk(
  'organizationReducer/createOrganization',
  async ({ name, description }, thunkAPI) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/organizations/`;
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ name, description })
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Created organization.',
              type: 'success'
            })
          );

          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const updateOrganization = createAsyncThunk(
  'organizationReducer/updateOrganization',
  async ({ id, name, description }, thunkAPI) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/organization/${id}`;
    try {
      return await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ name, description })
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Updated organization.',
              type: 'success'
            })
          );

          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  'organizationReducer/deleteOrganization',
  async ({ id }, thunkAPI) => {
    let token = Cookies.get('peekdata_access_token');
    let url = `${process.env.REACT_APP_API_BASE_URL}/organization/${id}`;
    try {
      return await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Deleted organization.',
              type: 'success'
            })
          );

          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

const organizationSlice = createSlice({
  name: 'organizationReducer',
  initialState: {
    currentOrganizationId: null,
    organizations: {},
    organization: {}
  },
  reducers: {
    setCurrentOrganizationId: (state, action) => {
      state.currentOrganizationId = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createOrganization.pending, (state) => {
        state.organization.loading = true;
        state.organization.data = null;
        state.organization.error = null;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.organization.loading = false;
        state.organization.data = action.payload;
        state.organization.error = null;
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.organization.loading = false;
        state.organization.data = null;
        state.organization.error = action.payload;
      })
      .addCase(updateOrganization.pending, (state) => {
        state.organization.loading = true;
        state.organization.data = null;
        state.organization.error = null;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.organization.loading = false;
        state.organization.data = action.payload;
        state.organization.error = null;
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.organization.loading = false;
        state.organization.data = null;
        state.organization.error = action.payload;
      })
      .addCase(deleteOrganization.pending, (state) => {
        state.organization.loading = true;
        state.organization.error = null;
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.organization.loading = false;
        state.organization.error = null;
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.organization.loading = false;
        state.organization.error = action.payload;
      })
      .addCase(listOrganizations.pending, (state) => {
        state.organizations.loading = true;
        state.organizations.data = null;
        state.organizations.error = null;
      })
      .addCase(listOrganizations.fulfilled, (state, action) => {
        state.organizations.loading = false;
        state.organizations.data = action.payload;
        state.organizations.error = null;
      })
      .addCase(listOrganizations.rejected, (state, action) => {
        state.organizations.loading = false;
        state.organizations.data = null;
        state.organizations.error = action.payload;
      });
  }
});

export const { setCurrentOrganizationId } = organizationSlice.actions;

export default organizationSlice.reducer;
