import React from 'react';

import { Box } from '@mui/material';

import styles from './BaseCard.module.scss';

export default function BaseCard(props) {
  return (
    <Box className={styles.card} onClick={(e) => e.stopPropagation()}>
      <Box className={styles.cardTitle} style={{ marginBottom: 20 }}>
        {props.title}
      </Box>
      {props.subtitle && (
        <Box
          style={{ marginBottom: 20 }}
          dangerouslySetInnerHTML={{ __html: props.subtitle }}
        />
      )}
      {props.children}
    </Box>
  );
}
