import LoadingBox from '../shared/LoadingBox';
import OrganizationCard from './OrganizationCard';
import OrganizationForm from './OrganizationForm';
import DeletePage from 'features/shared/DeletePage';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AddCircle } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';

import {
  deleteOrganization,
  listOrganizations
} from 'redux/slices/organizationSlice';

import styles from './OrganizationsIndexPage.module.scss';

export default function OrganizationsIndexPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [editModalData, setEditModalData] = useState(null);
  const organizations = useSelector(
    (state) => state.organizationSlice.organizations
  );

  const handleDeleteAction = () => {
    dispatch(deleteOrganization({ id: deleteModalData.entityId })).then(() => {
      dispatch(listOrganizations());
      setDeleteModalData(null);
    });
  };

  return (
    <>
      <Box className={styles.header}>
        <Box className={styles.title}>Organizations</Box>
        <Box style={{ marginLeft: 'auto' }}>
          <Button
            className={styles.button}
            onClick={() => navigate('/dashboard/organizations/new')}
          >
            <AddCircle style={{ fontSize: 28 }} />
            <span>Create new</span>
          </Button>
        </Box>
      </Box>
      <Box mt={2}>Use organizations to manage users and resources.</Box>
      <Grid container spacing={2} mt={2}>
        {(
          organizations.data ?? [
            { data: { name: 'dummy', id: '1' } },
            { data: { name: 'dummy', id: '2' } },
            { data: { name: 'dummy', id: '3' } }
          ]
        ).map((organizationData, index) => (
          <Grid item xs={12} md={12} lg={12} key={`organization-card-${index}`}>
            <LoadingBox
              style={{ borderRadius: 16 }}
              loading={organizations.loading}
            >
              <OrganizationCard
                data={organizationData}
                onEdit={(modalData) => setEditModalData(modalData)}
                onDelete={(modalData) => setDeleteModalData(modalData)}
              />
            </LoadingBox>
          </Grid>
        ))}
      </Grid>
      {deleteModalData && (
        <DeletePage
          {...deleteModalData}
          onCancel={() => setDeleteModalData(null)}
          onAction={handleDeleteAction}
        />
      )}
      {editModalData && (
        <OrganizationForm
          data={editModalData}
          onCancel={() => setEditModalData(null)}
          onAction={() => setEditModalData(null)}
        />
      )}
    </>
  );
}
