import { CLUSTER_TYPE_CHOICES } from 'shared/Constants';
import { CLUSTER_STATE_CHOICES } from 'shared/Constants';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Delete,
  MoreHoriz,
  PlayArrow,
  Publish,
  Settings,
  Stop,
  Terminal
} from '@mui/icons-material';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';

import {
  fetchClusterStatus,
  updateClusterStatus
} from 'redux/slices/clusterSlice';

import styles from './ClusterCard.module.scss';

const ACTIONS = ['Configure', 'Deploy', 'Start', 'Stop', 'Terminate'];

export default function ClusterCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.data.id) {
      dispatch(fetchClusterStatus({ id: props.data.id }));

      let interval = setInterval(() => {
        dispatch(fetchClusterStatus({ id: props.data.id }));
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [props.data.id]); // eslint-disable-line

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditItemClick = () => {
    setAnchorEl(null);

    navigate(`/dashboard/clusters/${props.data.id}/edit`);
  };

  const handleDeleteItemClick = () => {
    setAnchorEl(null);
    props.onDelete({
      entityType: 'cluster',
      entityName: props.data.name,
      entityId: props.data.id
    });
  };

  const fetchActionIcon = (action) => {
    switch (action) {
      case 'Configure':
        return <Settings fontSize="small" />;
      case 'Deploy':
        return <Publish fontSize="small" />;
      case 'Start':
        return <PlayArrow fontSize="small" />;
      case 'Stop':
        return <Stop fontSize="small" />;
      case 'Terminate':
        return <Delete fontSize="small" />;
      default:
        return null;
    }
  };

  const handleActionClick = (e) => {
    switch (e.currentTarget.innerText) {
      case 'Configure':
        handleEditItemClick();
        break;
      case 'Terminate':
        handleDeleteItemClick();
        break;
      default:
        setAnchorEl(null);

        var action;
        let actionText = e.target.innerText.toLowerCase();
        if (actionText === 'start') {
          action = 'ST';
        }
        if (actionText === 'stop') {
          action = 'SP';
        }
        if (actionText === 'deploy') {
          action = 'DL';
        }

        dispatch(
          updateClusterStatus({
            id: props.data.id,
            action: action
          })
        );
        break;
    }
  };

  const availableActions = Object.keys(props.data.cluster_actions ?? {}).map(
    (key) => (props.data.cluster_actions[key] === 'true' ? key : null)
  );

  const clusterType = CLUSTER_TYPE_CHOICES.find(
    (choice) => choice.value === props.data.cluster_type
  )?.name;

  const clusterState = CLUSTER_STATE_CHOICES.find(
    (choice) => choice.value === props.data.cluster_state
  )?.name;

  return (
    <Box className={styles.card}>
      <Box className={styles.cardTitle}>{props.data.name}</Box>
      {/* <Box className={styles.cardDescription}>Description</Box> */}
      <Box
        className={styles.cardFooter}
        style={{ paddingTop: 15, paddingBottom: 15 }}
      >
        <Box className={styles.cardFooterItem}>
          <Box className={styles.cardFooterItemLabel}>Status</Box>
          <Box className={styles.cardFooterItemValue}>
            {/* <span className={styles.statusDot} /> */}
            {clusterState}
          </Box>
        </Box>
        <Box className={styles.cardFooterItem}>
          <Box className={styles.cardFooterItemLabel}>Size</Box>
          <Box className={styles.cardFooterItemValue}>
            {props.data.cluster_size}
          </Box>
        </Box>
        <Box className={styles.cardFooterItem}>
          <Box className={styles.cardFooterItemLabel}>Type</Box>
          <Box className={styles.cardFooterItemValue}>{clusterType}</Box>
        </Box>
      </Box>
      <Box className={styles.cardFooter} style={{ borderTop: 'none' }}>
        <Box className={styles.cardFooterItem}>
          <Button className={styles.button}>
            <Terminal />
            <span>Connect</span>
          </Button>
          <Button className={styles.buttonSquare} onClick={handleClick}>
            <MoreHoriz style={{ color: '#343434' }} />
          </Button>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {ACTIONS.map((action, index) => (
          <MenuItem
            key={`cluster-card-menu-item-${index}`}
            className={styles.menuItem}
            onClick={handleActionClick}
            disabled={availableActions.includes(action.toLowerCase()) === false}
          >
            <ListItemIcon>{fetchActionIcon(action)}</ListItemIcon>
            <ListItemText className={styles.menuItemLabel}>
              {action}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
