import AuthLayout from './features/auth/AuthLayout';
import ForgotPasswordScreen from './features/auth/ForgotPasswordScreen';
import SignInScreen from './features/auth/SignInScreen';
import SignUpOrganizationScreen from './features/auth/SignUpOrganizationScreen';
// import SignUpAccountScreen from './features/auth/SignUpAccountScreen';
import SignUpScreen from './features/auth/SignUpScreen';
import ClustersIndexPage from './features/clusters/ClustersIndexPage';
import DashboardLayout from './features/dashboard/DashboardLayout';
import OrganizationsIndexPage from './features/organizations/OrganizationsIndexPage';
import SettingsIndexPage from './features/settings/SettingsIndexPage';
import AuthManager from './features/shared/AuthManager';
import SnackbarManager from './features/shared/SnackbarManager';
import ClusterForm from 'features/clusters/ClusterForm';
import DataModelForm from 'features/dataModels/DataModelForm';
import DataModelsIndexPage from 'features/dataModels/DataModelsIndexPage';
import OrganizationForm from 'features/organizations/OrganizationForm';

import { Navigate, Route, Routes } from 'react-router-dom';

import './App.scss';
import EnvironmentForm from 'features/environments/EnvironmentForm';

function App() {
  return (
    <div className="App">
      <AuthManager>
        <SnackbarManager />
        <Routes>
          <Route path="/" element={<Navigate to="/sign_in" />} />
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route path="clusters" element={<ClustersIndexPage />}></Route>
            <Route path="clusters/new" element={<ClusterForm />}></Route>
            <Route path="clusters/:id/edit" element={<ClusterForm />}></Route>
            <Route path="organizations" element={<OrganizationsIndexPage />} />
            <Route path="organizations/new" element={<OrganizationForm />} />
            <Route path="environments/new" element={<EnvironmentForm />} />
            <Route
              path="organizations/:id/edit"
              element={<OrganizationForm />}
            />
            <Route path="data_models" element={<DataModelsIndexPage />} />
            <Route path="data_models/new" element={<DataModelForm />} />
            <Route path="data_models/:id/edit" element={<DataModelForm />} />
            <Route path="/dashboard/account" element={<SettingsIndexPage />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/sign_up" element={<SignUpScreen />} />
            {/* <Route path="/account" element={<SignUpAccountScreen />} /> */}
            <Route
              path="/organization"
              element={<SignUpOrganizationScreen />}
            />
            <Route path="/sign_in" element={<SignInScreen />} />
            <Route path="/forgot_password" element={<ForgotPasswordScreen />} />
          </Route>
        </Routes>
      </AuthManager>
    </div>
  );
}

export default App;
