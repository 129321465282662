import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Snackbar } from '@mui/material';

import { removeSnackbarFromQueue } from 'redux/slices/uiSlice';

export default function SnackbarManager() {
  const dispatch = useDispatch();
  const uiState = useSelector((state) => state.uiSlice.snackbarQueue);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: 380,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 15,
        padding: 40
      }}
    >
      {uiState.map((item, index) => {
        return (
          <Snackbar
            open
            key={`snackbar-${index}`}
            autoHideDuration={7000}
            style={{ position: 'relative' }}
            onClose={() => {
              dispatch(removeSnackbarFromQueue());
            }}
          >
            <Alert
              severity={item.type}
              sx={{ width: '100%', fontFamily: 'Open Sans' }}
            >
              {item.message}
            </Alert>
          </Snackbar>
        );
      })}
    </div>
  );
}
