import clusterSlice from './slices/clusterSlice';
import environmentSlice from './slices/environmentSlice';
import organizationSlice from './slices/organizationSlice';
import rootSlice from './slices/rootSlice';
import uiSlice from './slices/uiSlice';
import userSlice from './slices/userSlice';
import dataModelSlice from './slices/dataModelSlice'

import { combineReducers, configureStore } from '@reduxjs/toolkit';

const combinedReducer = combineReducers({
  rootSlice,
  userSlice,
  uiSlice,
  organizationSlice,
  clusterSlice,
  environmentSlice,
  dataModelSlice
});

export const store = configureStore({
  reducer: combinedReducer
});
