import BaseInput from './BaseInput';

import React from 'react';

import { MenuItem, Select } from '@mui/material';

import styles from './BaseSelect.module.scss';

import iconArrowDown from 'static/icons/i_sidebar_arrow_down.svg';

export default function BaseSelect(props) {
  return (
    <>
      <Select
        disableUnderline
        displayEmpty
        input={
          <BaseInput
            style={{ height: 52, background: '#FFF', minWidth: 218 }}
            dynamicPlaceholderClassName={props.dynamicPlaceholderClassName}
          />
        }
        style={{ color: props.value === '' ? '#A2A2A2' : '#000' }}
        IconComponent={() => <img src={iconArrowDown} alt="arrow down" />}
        {...props}
      >
        {props.items.map((item) => {
          return (
            <MenuItem
              className={styles.inputMenuItem}
              value={item.value}
              key={item.value}
              disabled={item.value === ''}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}
