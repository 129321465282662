import DataModelConnectionRow from './DataModelConnectionRow';
import DataModelFileRow from './DataModelFileRow';
import BaseButton from 'components/base/BaseButton';
import BaseInput, { BaseInputGroup } from 'components/base/BaseInput';
import BaseSelect from 'components/base/BaseSelect';
import Cookies from 'js-cookie';
import { DATABASE_CHOICES, SCHEMA_LOCATION_TYPE } from 'shared/Constants';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AddCircle } from '@mui/icons-material';
import { Box, Button, Divider } from '@mui/material';

import {
  createDataModel,
  listDataModels,
  updateDataModel
} from 'redux/slices/dataModelSlice';
import { addSnackbarToQueue } from 'redux/slices/uiSlice';

import styles from './DataModelForm.module.scss';
import baseStyles from 'components/base/BaseStyles.module.scss';

export default function DataModelForm(props) {
  const [name, setName] = useState('');
  const [scopeName, setScopeName] = useState('');
  const [databaseType, setDatabaseType] = useState('');
  const [schemaLocationType, setSchemaLocationType] = useState('');
  const [schemaUrl, setSchemaUrl] = useState('');
  const [description, setDescription] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const dataModel = useSelector((state) => state.dataModelSlice.dataModel);
  const dataModels = useSelector((state) => state.dataModelSlice.dataModels);
  const [connections, setConnections] = useState([]);
  const [dataModelFiles, setDataModelFiles] = useState([]);
  const [dataModelFilesToDelete, setDataModelFilesToDelete] = useState([]);

  const titleVerb = id ? 'Edit' : 'Create';
  const actionVerb = id ? 'Update' : 'Create and setup schema';

  useEffect(() => {
    if ((dataModels?.data || []).length === 0) {
      dispatch(listDataModels());
    }

    if (id == null) {
      return;
    }

    let dataModel = dataModels?.data?.find((dataModel) => dataModel.id === id);
    loadState(dataModel);
  }, [id, dataModels]); // eslint-disable-line

  const loadState = (dataModel) => {
    if (dataModel) {
      setName(dataModel.name);
      setScopeName(dataModel.scope_name);
      setDatabaseType(dataModel.database_type);
      setSchemaLocationType(dataModel.schema_location_type);
      setSchemaUrl(dataModel.schema_url);
      setDescription(dataModel.description);
    }

    fetchDataModelData(id);
  };

  const fetchDataModelData = (id) => {
    let token = Cookies.get('peekdata_access_token');

    fetch(`${process.env.REACT_APP_API_BASE_URL}/datamodel/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      })
      .then((json) => {
        setConnections(json.connections);
        let files = json.data_model_files.sort(
          (a, b) => a.priority - b.priority
        );
        setDataModelFiles(files);
      });
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    let validationErrors = {};

    if (name.length === 0) {
      validationErrors = { ...validationErrors, name: 'should be present' };
    }

    if (scopeName.length === 0) {
      validationErrors = {
        ...validationErrors,
        scopeName: 'should be present'
      };
    }

    if (databaseType.length === 0) {
      validationErrors = {
        ...validationErrors,
        databaseType: 'should be present'
      };
    }

    if (schemaLocationType.length === 0) {
      validationErrors = {
        ...validationErrors,
        schemaLocationType: 'should be present'
      };
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors);
      return;
    }

    if (id) {
      commitConnections(() => {});
      commitFiles(() => {});
      setTimeout(() => {
        dispatch(
          updateDataModel({
            id: id,
            name,
            scopeName,
            databaseType,
            schemaLocationType,
            schemaUrl,
            description
          })
        ).then(() => {
          actionCallback();
        });
      }, 2000);
    } else {
      dispatch(
        createDataModel({
          name,
          description,
          scopeName,
          databaseType,
          schemaLocationType,
          schemaUrl
        })
      ).then(() => {
        actionCallback();
      });
    }
  };

  useEffect(() => {
    if (dataModel?.data == null) {
      return;
    }

    if (id) {
      // actionCallback();
    } else {
      let params = [
        `dataModelId=${dataModel.data.id}`,
        `dataModelName=${dataModel.data.name}`,
        `scopeName=${dataModel.data.scope_name}`
      ];
      let url = `${process.env.REACT_APP_QUICK_SETUP_BASE_URL}/dashboard/data_models/setup`;
      window.location.href = `${url}?${params.join('&')}`;
    }
  }, [dataModel]); // eslint-disable-line

  const actionCallback = () => {
    dispatch(listDataModels());
    if (props.onAction) {
      props.onAction();
    }
    navigate('/dashboard/data_models');
  };

  const addDataModelFile = () => {
    let newFiles = [...dataModelFiles];

    newFiles.unshift({
      name: 'New file',
      content: {},
      priority: 0
    });

    newFiles = newFiles.map((file, index) => {
      file.priority = index;
      return file;
    });

    setDataModelFiles(newFiles);
  };

  const removeDataModelFile = (priority) => {
    if (
      priority != null &&
      dataModelFilesToDelete.includes(priority) === false
    ) {
      if (dataModelFiles[priority].id != null) {
        let newFiles = [...dataModelFilesToDelete];
        newFiles.push(priority);
        setDataModelFilesToDelete(newFiles);
      } else {
        let newFiles = dataModelFiles.filter(
          (file) => file.priority !== priority
        );
        setDataModelFiles(newFiles);
      }
    }
  };

  const commitConnections = (callback) => {
    connections.forEach((connection, index) => {
      let token = Cookies.get('peekdata_access_token');
      let url = connection.id
        ? `${process.env.REACT_APP_API_BASE_URL}/datamodel/connection/${connection.id}`
        : `${process.env.REACT_APP_API_BASE_URL}/datamodel/connection/`;
      var method = connection.id ? 'PUT' : 'POST';
      if (connection.shouldDelete) {
        method = 'DELETE';
      }

      let payload = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: {
          name: connection.name,
          username: connection.username,
          password: connection.password,
          url: connection.url,
          driver: connection.driver
        }
      };

      if (method === 'DELETE') {
        delete payload.body;
      }

      if (method === 'POST') {
        payload.body.datamodel_id = id;
      }

      payload.body = JSON.stringify(payload.body);

      fetch(url, payload)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((json) => {
              dispatch(
                addSnackbarToQueue({
                  message: 'Something went wrong.',
                  type: 'error'
                })
              );
              throw json;
            });
          }
        })
        .then((json) => {
          if (connections.length - 1 === index) {
            callback();
          }
        });
    });
  };

  const commitFiles = (callback) => {
    dataModelFiles.forEach((file, index) => {
      let token = Cookies.get('peekdata_access_token');
      let url = file.id
        ? `${process.env.REACT_APP_API_BASE_URL}/datamodel/file/${file.id}`
        : `${process.env.REACT_APP_API_BASE_URL}/datamodel/file/`;
      var method = file.id ? 'PUT' : 'POST';
      if (dataModelFilesToDelete.includes(file.priority)) {
        method = 'DELETE';
      }

      let payload = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          datamodel_id: id,
          name: file.name,
          content: file.content,
          priority: file.priority
        })
      };

      if (method === 'DELETE') {
        delete payload.body;
      }

      fetch(url, payload)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((json) => {
              dispatch(
                addSnackbarToQueue({
                  message: 'Something went wrong.',
                  type: 'error'
                })
              );
              throw json;
            });
          }
        })
        .then((json) => {
          if (dataModelFiles.length - 1 === index) {
            callback();
          }
        });
    });
  };

  const uploadDataModelFile = (priority) => {
    let newFiles = [...dataModelFiles];

    let fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.json';
    fileInput.onchange = (e) => {
      let file = e.target.files[0];
      let reader = new FileReader();

      reader.onload = (e) => {
        let content = JSON.parse(e.target.result);
        newFiles = newFiles.map((file) => {
          if (file.priority === priority) {
            file.content = content;
          }
          return file;
        });

        setDataModelFiles(newFiles);
      };
      reader.readAsText(file);
    };
    fileInput.click();
  };

  const addDataModelConnection = () => {
    let newConnections = [...connections];
    newConnections.unshift({
      name: '',
      username: '',
      password: '',
      url: '',
      id: null
    });
    setConnections(newConnections);
  };

  const removeDataModelConnection = (index) => {
    if (connections[index].id == null) {
      let newConnections = connections.filter((connection, i) => i !== index);
      setConnections(newConnections);
    } else {
      let newConnections = [...connections];
      newConnections[index].shouldDelete = true;
      setConnections(newConnections);
    }
  };

  const onConnectionChange = (index, key, value) => {
    let newConnections = connections.map((connection, i) => {
      if (i === index) {
        connection[key] = value;
      }
      return connection;
    });
    setConnections(newConnections);
  };

  // show menu
  const handlePriorityButtonClick = (filePriority, direction) => {
    // move the file up or down in the list, changing the priority
    let newFiles = [...dataModelFiles];
    let file = newFiles.find((file) => file.priority === filePriority);
    let index = newFiles.indexOf(file);

    if (direction === 'up' && index > 0) {
      let temp = newFiles[index - 1];
      newFiles[index - 1] = newFiles[index];
      newFiles[index] = temp;
    }

    if (direction === 'down' && index < newFiles.length - 1) {
      let temp = newFiles[index + 1];
      newFiles[index + 1] = newFiles[index];
      newFiles[index] = temp;
    }

    newFiles = newFiles.map((file, index) => {
      file.priority = index;
      return file;
    });

    setDataModelFiles(newFiles);
  };

  return (
    <Box style={{ position: 'relative', paddingBottom: 82 }}>
      <Box className={styles.header}>
        <Box className={styles.title}>{titleVerb} data model</Box>
      </Box>
      <Box mt={2} style={{ maxWidth: 550 }}>
        {id ? 'Use' : 'Create'} a data model to define the structure of your
        data. After creating a model, you'll be redirected to the schema setup
        page.
      </Box>
      <Box style={{ marginTop: 20, maxWidth: 550 }}>
        <form onSubmit={handleSubmit}>
          <BaseInputGroup>
            <BaseInput
              dynamicPlaceholder
              placeholder="Name"
              autoComplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
              validationError={validationErrors.name}
            />
            <BaseInput
              dynamicPlaceholder
              placeholder="Scope name"
              autoComplete="off"
              value={scopeName}
              onChange={(e) => setScopeName(e.target.value)}
              validationError={validationErrors.scopeName}
            />
            <BaseSelect
              dynamicPlaceholder
              placeholder="Database type"
              autoComplete="off"
              value={databaseType}
              onChange={(e) => setDatabaseType(e.target.value)}
              items={DATABASE_CHOICES}
              validationError={validationErrors.databaseType}
            />
            <BaseSelect
              dynamicPlaceholder
              placeholder="Schema location type"
              autoComplete="off"
              value={schemaLocationType}
              onChange={(e) => setSchemaLocationType(e.target.value)}
              items={SCHEMA_LOCATION_TYPE}
              validationError={validationErrors.schemaLocationType}
            />
            <BaseInput
              dynamicPlaceholder
              placeholder="Schema URL (optional)"
              autoComplete="off"
              value={schemaUrl}
              onChange={(e) => setSchemaUrl(e.target.value)}
              validationError={validationErrors.schemaUrl}
            />
            <BaseInput
              dynamicPlaceholder
              placeholder="Description (optional)"
              autoComplete="off"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              validationError={validationErrors.description}
            />
          </BaseInputGroup>
          {id && (
            <>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Box>
                <Box
                  className={baseStyles.defaultFlex}
                  style={{ marginBottom: 10 }}
                >
                  <h3 style={{ margin: 0 }}>Connections</h3>
                  <Button
                    className={`${baseStyles.buttonSm} ${baseStyles.add}`}
                    onClick={addDataModelConnection}
                  >
                    <AddCircle style={{ fontSize: 20 }} />
                    <span>Add connection</span>
                  </Button>
                </Box>
                {connections.length === 0 && (
                  <p>There are no connections for this data model.</p>
                )}
                {connections.length !== 0 &&
                  connections.map((connection, index) => {
                    return (
                      <DataModelConnectionRow
                        shouldDelete={connection.shouldDelete}
                        key={connection.id}
                        connection={connection}
                        onDeleteUndo={(index) => {
                          let newConnections = connections.map((connection) => {
                            connection.shouldDelete = false;
                            return connection;
                          });
                          setConnections(newConnections);
                        }}
                        index={index}
                        isLast={props.index === connections.length - 1}
                        onRemove={removeDataModelConnection}
                        onConnectionChange={onConnectionChange}
                      />
                    );
                  })}
              </Box>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              <Box>
                <Box className={baseStyles.defaultFlex}>
                  <h3 style={{ margin: 0 }}>Configuration files</h3>
                  <Button
                    className={`${baseStyles.buttonSm} ${baseStyles.add}`}
                    onClick={addDataModelFile}
                  >
                    <AddCircle style={{ fontSize: 20 }} />
                    <span>Add file</span>
                  </Button>
                </Box>
                {dataModelFiles.length === 0 && (
                  <p>There are no configuration files.</p>
                )}
                {dataModelFiles.length !== 0 &&
                  dataModelFiles.map((file, index) => {
                    return (
                      <DataModelFileRow
                        key={`${file.priority}-${index}`}
                        file={file}
                        shouldDelete={dataModelFilesToDelete.includes(
                          file.priority
                        )}
                        className={styles.fadeIn}
                        onRemove={removeDataModelFile}
                        onDeleteUndo={(priority) => {
                          let newFiles = dataModelFilesToDelete.filter(
                            (filePriority) => filePriority !== priority
                          );
                          setDataModelFilesToDelete(newFiles);
                        }}
                        onNameChange={(name, priority) => {
                          let newFiles = dataModelFiles.map((file) => {
                            if (file.priority === priority) {
                              file.name = name;
                            }
                            return file;
                          });

                          setDataModelFiles(newFiles);
                        }}
                        onContentChange={(content, priority) => {
                          let newFiles = dataModelFiles.map((file) => {
                            if (file.priority === priority) {
                              file.content = JSON.parse(content);
                            }
                            return file;
                          });

                          setDataModelFiles(newFiles);
                        }}
                        onUpload={uploadDataModelFile}
                        priorityUpButtonDisabled={file.priority === 0}
                        priorityDownButtonDisabled={
                          file.priority === dataModelFiles.length - 1
                        }
                        onPriorityUp={() =>
                          handlePriorityButtonClick(file.priority, 'up')
                        }
                        onPriorityDown={() =>
                          handlePriorityButtonClick(file.priority, 'down')
                        }
                      />
                    );
                  })}
              </Box>
            </>
          )}
          <Box className={baseStyles.formBottomBar}>
            <Box className={baseStyles.formBottomBarContent}>
              <Button
                className={baseStyles.underlinedButton}
                onClick={() => navigate('/dashboard/data_models')}
              >
                Cancel
              </Button>
              <BaseButton
                type="submit"
                title={actionVerb}
                style={{ marginTop: 0, maxWidth: id ? 200 : 300 }}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
