import React, { useEffect, useState } from 'react';

import { Box, Input } from '@mui/material';

import styles from './BaseInput.module.scss';

export default function BaseInput(props) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (props.value) {
      setIsActive(true);
    }
  }, [props.value]);

  return (
    <Box style={{ position: 'relative', ...props.wrapperStyle }}>
      <Input
        {...props}
        placeholder={props.dynamicPlaceholder ? null : props.placeholder}
        className={`${styles.wrapper} ${props.className}`}
        disableUnderline
        onFocus={() => setIsActive(true)}
        onBlur={() => {
          if (!props.value) {
            setIsActive(false);
          }
        }}
      />
      {props.validationError && (
        <Box className={styles.error}>{props.validationError}</Box>
      )}
      {props.dynamicPlaceholder && (
        <Box
          className={`${styles.placeholder} ${
            props.dynamicPlaceholderClassName
          } ${isActive && styles.active}`}
        >
          {props.placeholder}
        </Box>
      )}
    </Box>
  );
}

function BaseInputGroup(props) {
  return <Box className={styles.group}>{props.children}</Box>;
}

export { BaseInputGroup };
