import BaseButton from 'components/base/BaseButton';
import BaseInput, { BaseInputGroup } from 'components/base/BaseInput';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import {
  resetPasswordChangeStatus,
  updateUser,
  updateUserPassword
} from 'redux/slices/userSlice';

import styles from './SettingsIndexPage.module.scss';

export default function SettingsIndexPage() {
  const [name, setName] = useState('');
  const user = useSelector((state) => state.userSlice.user);
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [infoValidationErrors, setInfoValidationErrors] = useState({});
  const [passwordValidationErrors, setPasswordValidationErrors] = useState({});

  useEffect(() => {
    if (user?.data?.name != null) {
      setName(user.data.name);
    }
  }, [user]);

  useEffect(() => {
    if (user?.data?.passwordChanged) {
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      dispatch(resetPasswordChangeStatus());
    }
  }, [user?.data?.passwordChanged]); // eslint-disable-line

  const handleAccountUpdateButtonClick = () => {
    let validationErrors = {};

    if (name.length === 0) {
      validationErrors.name = 'should be present';
    }

    if (Object.keys(validationErrors).length > 0) {
      setInfoValidationErrors(validationErrors);
      return;
    }

    dispatch(updateUser({ email: user.data.email, name }));
  };

  const handlePasswordUpdateButtonClick = () => {
    let validationErrors = {};

    if (oldPassword.length === 0) {
      validationErrors.oldPassword = 'should be present';
    }

    if (newPassword.length === 0) {
      validationErrors.newPassword = 'should be present';
    }

    if (confirmNewPassword.length === 0) {
      validationErrors.confirmNewPassword = 'should be present';
    }

    if (newPassword !== confirmNewPassword) {
      validationErrors.confirmNewPassword = 'should match new password';
    }

    if (Object.keys(validationErrors).length > 0) {
      setPasswordValidationErrors(validationErrors);
      return;
    }

    dispatch(
      updateUserPassword({ oldPassword, newPassword, confirmNewPassword })
    );
  };

  return (
    <>
      <Box className={styles.header}>
        <Box className={styles.title}>Account</Box>
      </Box>
      <Box mt={2}>Manage your account information.</Box>
      <Box style={{ maxWidth: 550 }}>
        <Box style={{ marginTop: 30 }}>
          <BaseInputGroup>
            <Box className={styles.sectionTitle}>Info</Box>
            <BaseInput
              placeholder="Full name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              validationError={infoValidationErrors.name}
            />
          </BaseInputGroup>
          <BaseButton
            title="Update"
            onClick={handleAccountUpdateButtonClick}
            style={{ maxWidth: 250 }}
          />
        </Box>
        <Box className={styles.divider} />
        <Box>
          <BaseInputGroup>
            <Box className={styles.sectionTitle}>Password</Box>
            <BaseInput
              type="password"
              placeholder="Current password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              validationError={passwordValidationErrors.oldPassword}
            />
            <BaseInput
              type="password"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              validationError={passwordValidationErrors.newPassword}
            />
            <BaseInput
              type="password"
              placeholder="Password confirmation"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              validationError={passwordValidationErrors.confirmNewPassword}
            />
          </BaseInputGroup>
          <BaseButton
            title="Change password"
            onClick={handlePasswordUpdateButtonClick}
            style={{ maxWidth: 250 }}
          />
        </Box>
        <Box className={styles.divider} />
        <Box>
          <BaseInputGroup>
            <Box className={styles.sectionTitle}>Account</Box>
          </BaseInputGroup>
          <BaseButton
            title="Delete my account"
            className={styles.deleteButton}
            style={{ maxWidth: 250 }}
          />
        </Box>
      </Box>
    </>
  );
}
