import React from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Backdrop, Box, Button } from '@mui/material';

import styles from './BaseBackdrop.module.scss';

export default function BaseBackdrop(props) {
  return (
    <Backdrop className={styles.wrapper} open={true} onClick={props.onClose}>
      <Box style={{ maxWidth: 550, flexGrow: 1 }}>
        <Button className={styles.button} onClick={props.onClose}>
          <ArrowBack style={{ fontSize: 18 }} />
          <span>Back</span>
        </Button>
        {props.children}
      </Box>
    </Backdrop>
  );
}
