import BaseButton from 'components/base/BaseButton';
import BaseInput, { BaseInputGroup } from 'components/base/BaseInput';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import {
  createOrganization,
  listOrganizations,
  updateOrganization
} from 'redux/slices/organizationSlice';

import styles from './OrganizationForm.module.scss';
import baseStyles from 'components/base/BaseStyles.module.scss';

export default function OrganizationForm(props) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const organizations = useSelector(
    (state) => state.organizationSlice.organizations
  );

  const titleVerb = id ? 'Edit' : 'Create';
  const actionVerb = id ? 'Update' : 'Create';

  useEffect(() => {
    if (id == null) {
      return;
    }

    let organization = organizations?.data?.find(
      (organization) => organization.id === id
    );

    if (organization) {
      setName(organization.name);
      setDescription(organization.description);
    }
  }, [id]); // eslint-disable-line

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name.length === 0) {
      setValidationErrors({ name: 'should be present' });
      return;
    }

    if (id) {
      dispatch(
        updateOrganization({ id: props.data.id, name, description })
      ).then(() => {
        actionCallback();
      });
    } else {
      dispatch(createOrganization({ name, description })).then(() => {
        actionCallback();
      });
    }
  };

  const actionCallback = () => {
    dispatch(listOrganizations());
    if (props.onAction) {
      props.onAction();
    }
    navigate('/dashboard/clusters');
  };

  return (
    <Box style={{ position: 'relative', paddingBottom: 82 }}>
      <Box className={styles.header}>
        <Box className={styles.title}>{titleVerb} organization</Box>
      </Box>
      <Box mt={2} style={{ maxWidth: 550 }}>
        {id ? 'Use' : 'Create'} an organization to manage your clusters and
        users.
      </Box>
      <Box style={{ marginTop: 20, maxWidth: 550 }}>
        <form onSubmit={handleSubmit}>
          <BaseInputGroup>
            <BaseInput
              dynamicPlaceholder
              placeholder="Name"
              autoComplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
              validationError={validationErrors.name}
            />
            <BaseInput
              dynamicPlaceholder
              placeholder="Description (optional)"
              autoComplete="off"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              validationError={validationErrors.description}
            />
          </BaseInputGroup>
          <Box className={baseStyles.formBottomBar}>
            <Box className={baseStyles.formBottomBarContent}>
              <Button
                className={baseStyles.underlinedButton}
                onClick={() => navigate('/dashboard/organizations')}
              >
                Cancel
              </Button>
              <BaseButton
                type="submit"
                title={actionVerb}
                style={{ marginTop: 0, maxWidth: 200 }}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
