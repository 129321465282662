import BodyStyle from '../shared/BodyStyle';
import LoadingBox from '../shared/LoadingBox';
import DashboardModalManager from './DashboardModalManager';
import BaseInput from 'components/base/BaseInput';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom';

import {
  AddCircle,
  BallotRounded,
  Dataset,
  ElevatorRounded,
  HelpCenter,
  SettingsApplications
} from '@mui/icons-material';
import { Box, Button, MenuItem, Select } from '@mui/material';

import {
  listOrganizations,
  setCurrentOrganizationId
} from 'redux/slices/organizationSlice';
import { signOutUser } from 'redux/slices/userSlice';

import styles from './DashboardLayout.module.scss';

import iconArrowDown from 'static/icons/i_sidebar_arrow_down.svg';
import iconArrow from 'static/icons/i_sidebar_arrow_right.svg';
import logo from 'static/images/logo_c.svg';

export default function DashboardLayout(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.userSlice.session);
  const user = useSelector((state) => state.userSlice.user);
  const organizations = useSelector(
    (state) => state.organizationSlice.organizations
  );
  const currentOrganizationId = useSelector(
    (state) => state.organizationSlice.currentOrganizationId
  );
  const location = useLocation();

  useEffect(() => {
    dispatch(listOrganizations());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (organizations.data?.length > 0) {
      dispatch(setCurrentOrganizationId(organizations.data[0].id));
    }
  }, [organizations]); // eslint-disable-line

  useEffect(() => {
    // if (!session.data) {
    //   navigate('/');
    // }
  }, [session, navigate]);

  const handleLogoutButtonClick = () => {
    dispatch(signOutUser());
  };

  return (
    <Box className={styles.wrapper}>
      <DashboardModalManager />
      <BodyStyle background="#F8F8F8" />
      <Box className={styles.sidebar}>
        <Link to="/dashboard/clusters" className={styles.logo}>
          <img
            src={logo}
            width={140}
            height={35}
            style={{ marginLeft: 0 }}
            alt="logo"
          />
          <span>beta</span>
        </Link>
        <Box className={styles.menu}>
          <Box className={styles.menuItemWithLabel}>
            <Box className={styles.menuItemLabel}>Organization</Box>
            <LoadingBox
              style={{ borderRadius: 10 }}
              loading={organizations.loading}
            >
              <Select
                className={styles.input}
                disableUnderline
                displayEmpty
                value={currentOrganizationId}
                input={<BaseInput />}
                IconComponent={() => (
                  <img src={iconArrowDown} alt="arrow down" />
                )}
                onChange={(e) => {
                  if (e.target.value === 'create') {
                    navigate('/dashboard/organizations/new');
                  } else {
                    dispatch(setCurrentOrganizationId(e.target.value));
                  }
                }}
              >
                {organizations.data?.map((item) => (
                  <MenuItem
                    className={styles.inputMenuItem}
                    value={item.id}
                    key={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))}
                {organizations.data?.length === 0 && (
                  <MenuItem
                    className={styles.inputMenuItem}
                    value={null}
                    disabled
                  >
                    No organizations
                  </MenuItem>
                )}
                <MenuItem className={styles.inputMenuItem} value={'create'}>
                  <AddCircle style={{ marginRight: 8 }} />
                  <strong>Create new</strong>
                </MenuItem>
              </Select>
            </LoadingBox>
          </Box>
          <Box className={styles.menuDivider} />
          <Box className={styles.menuItemLabel}>Manage</Box>
          <NavLink
            to="/dashboard/clusters"
            className={() =>
              `${styles.menuItem} ${
                /clusters/.test(location.pathname) && styles.active
              }`
            }
          >
            <BallotRounded htmlColor="#BF4E44" style={{ fontSize: 32 }} />
            <span>Clusters</span>
            <img src={iconArrow} alt="arrow" />
          </NavLink>
          <NavLink
            to="/dashboard/organizations"
            className={() =>
              `${styles.menuItem} ${
                /organizations/.test(location.pathname) && styles.active
              }`
            }
          >
            <ElevatorRounded htmlColor="#BF4E44" style={{ fontSize: 32 }} />
            <span>Organizations</span>
            <img src={iconArrow} alt="arrow" />
          </NavLink>
          <NavLink
            to="/dashboard/data_models"
            className={() =>
              `${styles.menuItem} ${
                /dataModels/.test(location.pathname) && styles.active
              }`
            }
          >
            <Dataset htmlColor="#BF4E44" style={{ fontSize: 32 }} />
            <span>Data models</span>
            <img src={iconArrow} alt="arrow" />
          </NavLink>
          <NavLink
            to="/dashboard/account"
            className={() =>
              `${styles.menuItem} ${
                /account/.test(location.pathname) && styles.active
              }`
            }
          >
            <SettingsApplications
              htmlColor="#BF4E44"
              style={{ fontSize: 32 }}
            />
            <span>Account</span>
            <img src={iconArrow} alt="arrow" />
          </NavLink>
          <Box className={styles.helpTooltip}>
            <HelpCenter htmlColor="#BF4E44" style={{ fontSize: 32 }} />
            <span>
              Got questions? React out to us at{' '}
              <a href="mailto:support@peekdata.io">support@peekdata.io</a>
            </span>
          </Box>
        </Box>
        <Box className={styles.sidebarFooter}>
          <LoadingBox loading={user.loading} style={{ borderRadius: 10 }}>
            <Box className={styles.sidebarFooterRow}>
              <Box className={styles.profile}>
                <Box className={styles.profileAvatar}>
                  {user.data?.name?.split(' ')?.map((name) => name[0])}
                </Box>
                <Box className={styles.profileName}>{user.data?.name}</Box>
              </Box>
              <Button
                className={styles.logout}
                onClick={handleLogoutButtonClick}
              >
                Sign out
              </Button>
            </Box>
            {/* <Box className={styles.menuDivider} /> */}
            {/* <Button className={styles.supportButton} style={{ width: '100%' }}>
            <ContactSupport htmlColor="#BF4E44" style={{ fontSize: 32 }} />
            Contact support
          </Button> */}
          </LoadingBox>
        </Box>
      </Box>
      <Box className={styles.pageWrapper}>
        <Outlet />
        {props.children}
      </Box>
    </Box>
  );
}
