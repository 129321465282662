import Cookies from 'js-cookie';



import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



import { fetchCurrentUser } from 'redux/slices/userSlice';
import { renewSession } from 'redux/slices/userSlice';


export default function AuthManager(props) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let session = useSelector((state) => state.userSlice.session);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    if (session.pendingAction === 'signOut') {
      return;
    }

    if (session.data) {
      dispatch(fetchCurrentUser());
      ['/', '/sign_in', '/sign_up'].includes(window.location.pathname) &&
        navigate('/dashboard/clusters');
    } else {
      let accessToken = Cookies.get('peekdata_access_token');
      let refreshToken = Cookies.get('peekdata_refresh_token');

      if (accessToken && refreshToken) {
        dispatch(
          renewSession({
            access: accessToken,
            refresh: refreshToken
          })
        )
        
        setIsAllowed(true);
      } else {
        navigate('/sign_in');
      }
    }
  }, [session.data, session.pendingAction]); // eslint-disable-line

  useEffect(() => {
    if (['/', '/sign_in', '/sign_up'].includes(window.location.pathname)) {
      setIsAllowed(true);
    }
  }, [window.location.href]); // eslint-disable-line

  useEffect(() => {
    if (session.isTokenExpired === true) {
      Cookies.remove('peekdata_access_token');
      Cookies.remove('peekdata_refresh_token');
      navigate('/');
    }
  }, [session.isTokenExpired]); // eslint-disable-line

  return <>{isAllowed && props.children}</>;
}