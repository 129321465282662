import LoadingBox from '../shared/LoadingBox';
import DataModelCard from './DataModelCard';
import DataModelForm from './DataModelForm';
import DeletePage from 'features/shared/DeletePage';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AddCircle } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';

import { deleteDataModel, listDataModels } from 'redux/slices/dataModelSlice';
import { addSnackbarToQueue } from 'redux/slices/uiSlice';

import styles from './DataModelsIndexPage.module.scss';

export default function DataModelsIndexPage() {
  const dispatch = useDispatch();
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [editModalData, setEditModalData] = useState(null);
  const dataModels = useSelector((state) => state.dataModelSlice.dataModels);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listDataModels());
  }, [dispatch]);

  useEffect(() => {
    if (searchParams.get('toast') === 'configured') {
      dispatch(
        addSnackbarToQueue({
          message: 'Data model configured successfully.',
          type: 'success'
        })
      );
    }
  }, [searchParams]); // eslint-disable-line

  const handleDeleteAction = () => {
    dispatch(deleteDataModel({ id: deleteModalData.entityId })).then(() => {
      dispatch(listDataModels());
      setDeleteModalData(null);
    });
  };

  return (
    <>
      <Box className={styles.header}>
        <Box className={styles.title}>Data models</Box>
        <Box style={{ marginLeft: 'auto' }}>
          <Button
            className={styles.button}
            onClick={() => navigate('/dashboard/data_models/new')}
          >
            <AddCircle style={{ fontSize: 28 }} />
            <span>Create new</span>
          </Button>
        </Box>
      </Box>
      <Box mt={2}>Use and manage data models to configure your clusters.</Box>
      {dataModels.data && dataModels.data.length === 0 && (
        <Box mt={5} style={{ opacity: 0.5 }}>
          <strong>There are no data models yet.</strong>
        </Box>
      )}
      <Grid container spacing={2} mt={2}>
        {(
          dataModels.data ?? [
            { data: { name: 'dummy', id: '1' } },
            { data: { name: 'dummy', id: '2' } },
            { data: { name: 'dummy', id: '3' } }
          ]
        ).map((dataModelData, index) => (
          <Grid item xs={12} md={6} lg={6} key={`dataModel-card-${index}`}>
            <LoadingBox
              style={{ borderRadius: 16 }}
              loading={dataModels.loading}
            >
              <DataModelCard
                data={dataModelData}
                onEdit={(modalData) => setEditModalData(modalData)}
                onDelete={(modalData) => setDeleteModalData(modalData)}
              />
            </LoadingBox>
          </Grid>
        ))}
      </Grid>
      {deleteModalData && (
        <DeletePage
          {...deleteModalData}
          onCancel={() => setDeleteModalData(null)}
          onAction={handleDeleteAction}
        />
      )}
      {editModalData && (
        <DataModelForm
          data={editModalData}
          onCancel={() => setEditModalData(null)}
          onAction={() => setEditModalData(null)}
        />
      )}
    </>
  );
}
