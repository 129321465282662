import React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import styles from './ClusterGetStartedCard.module.scss';

export default function ClusterGetStartedCard(props) {
  let isEnvironmentDisabled =
    props.isOrganizationPresent === false ||
    props.isEnvironmentPresent === true;

  let isClusterDisabled = props.isEnvironmentPresent === false;

  return (
    <>
      <Box className={styles.sectionTitle}>Get started</Box>
      To get started, let's go through the following steps:
      <Box className={styles.flex}>
        <Box
          className={`
            ${props.isOrganizationPresent && styles.done}
            ${props.isOrganizationPresent && styles.disabled}
          `}
        >
          <strong>1.</strong>{' '}
          <Link to="/dashboard/organizations/new">Create an organization</Link>{' '}
          to manage resources and users.
        </Box>
        <Box
          className={`${isEnvironmentDisabled && styles.disabled} ${
            props.isEnvironmentPresent && styles.done
          }`}
        >
          <strong>2.</strong>{' '}
          <Link to="/dashboard/environments/new">Create an environment</Link> to
          use it as a scope for clusters.
        </Box>
        <Box className={isClusterDisabled && styles.disabled}>
          <strong>3.</strong>{' '}
          <Link to="/dashboard/clusters/new">
            Configure and deploy your first cluster
          </Link>{' '}
          to have a fully functional REST API running.
        </Box>
      </Box>
    </>
  );
}
