import { addSnackbarToQueue } from './uiSlice';
import Cookies from 'js-cookie';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const signInUser = createAsyncThunk(
  'userReducer/signInUser',
  async ({ email, password }, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/login/`;
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      })
        .then((response) => {
          if (response.ok) {
            thunkAPI.dispatch(
              addSnackbarToQueue({
                message: 'Signed in.',
                type: 'success'
              })
            );

            return response.json();
          } else {
            return response.json().then((json) => {
              throw json;
            });
          }
        })
        .then((json) => {
          Cookies.set('peekdata_access_token', json.access);
          Cookies.set('peekdata_refresh_token', json.refresh);
        });
    } catch (error) {
      let message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const signUpUser = createAsyncThunk(
  'userReducer/signUpUser',
  async ({ email, password, name }, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/register/`;
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password, name })
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Signed up.',
              type: 'success'
            })
          );

          thunkAPI.dispatch(signInUser({ email, password }));

          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message =
        error.detail ||
        (error.email ?? [])[0] ||
        (error.password ?? [])[0] ||
        (error.name ?? [])[0] ||
        error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const signOutUser = createAsyncThunk(
  'userReducer/signOutUser',
  async (_, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/logout/`;
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('peekdata_access_token')}`
        }
      }).then((response) => {
        if (response.ok) {
          Cookies.remove('peekdata_access_token');
          Cookies.remove('peekdata_refresh_token');

          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Signed out.',
              type: 'success'
            })
          );

          return {};
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      let message = error.detail || error.message;
      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const fetchCurrentUser = createAsyncThunk(
  'userReducer/fetchCurrentUser',
  async (_, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/user/`;
    try {
      return await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('peekdata_access_token')}`
        }
      }).then((response) => {
        thunkAPI.dispatch(refreshToken());

        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      var message = error.detail || error.message;

      if (message.includes('Given token not valid for any token type')) {
        message = 'Your session has expired. Please sign in again.';

        thunkAPI.dispatch(userSlice.actions.markTokenAsExpired());
      }

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  'userReducer/updateUser',
  async ({ email, name }, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/user/`;
    try {
      return await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('peekdata_access_token')}`
        },
        body: JSON.stringify({ email, name })
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Updated user.',
              type: 'success'
            })
          );

          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      var message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  'userReducer/updateUserPassword',
  async ({ oldPassword, newPassword, confirmNewPassword }, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/user/change_password/`;
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('peekdata_access_token')}`
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
          confirm_new_password: confirmNewPassword
        })
      }).then((response) => {
        if (response.ok) {
          thunkAPI.dispatch(
            addSnackbarToQueue({
              message: 'Updated password.',
              type: 'success'
            })
          );

          return response.json();
        } else {
          return response.json().then((json) => {
            throw json;
          });
        }
      });
    } catch (error) {
      var message = error.detail || error.message;

      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

export const refreshToken = createAsyncThunk(
  'userReducer/refreshToken',
  async (_, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/login/refresh/`;
    try {
      return await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('peekdata_access_token')}`
        },
        body: JSON.stringify({
          refresh: Cookies.get('peekdata_refresh_token')
        })
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((json) => {
              throw json;
            });
          }
        })
        .then((json) => {
          Cookies.set('peekdata_access_token', json.access);
          // Cookies.set('peekdata_refresh_token', json.refresh);
        });
    } catch (error) {
      let message = error.detail || error.message;
      thunkAPI.dispatch(
        addSnackbarToQueue({
          message: message,
          type: 'error'
        })
      );
    }
  }
);

const userSlice = createSlice({
  name: 'userReducer',
  initialState: {
    user: {},
    session: {}
  },
  reducers: {
    renewSession: (state, action) => {
      state.session.loading = false;
      state.session.data = action.payload;
      state.session.error = null;
    },
    markTokenAsExpired: (state, action) => {
      state.session.isTokenExpired = true;
    },
    resetPasswordChangeStatus: (state, action) => {
      state.user.data.passwordChanged = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(signInUser.pending, (state) => {
        state.session.loading = true;
        state.session.data = null;
        state.session.error = null;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.session.loading = false;
        state.session.data = action.payload;
        state.session.error = null;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.session.loading = false;
        state.session.data = null;
        state.session.error = action.payload;
      })
      .addCase(signUpUser.pending, (state) => {
        state.user.loading = true;
        state.user.data = null;
        state.user.error = null;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.user.loading = false;
        state.user.data = action.payload;
        state.user.error = null;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.user.loading = false;
        state.user.data = null;
        state.user.error = action.payload;
      })
      .addCase(signOutUser.pending, (state) => {
        state.session.loading = true;
        state.session.pendingAction = 'signOut';
        state.session.error = null;
      })
      .addCase(signOutUser.fulfilled, (state, action) => {
        state.session.loading = false;
        state.session.data = null;
        state.session.pendingAction = null;
        state.session.error = null;
      })
      .addCase(signOutUser.rejected, (state, action) => {
        state.session.loading = false;
        state.session.pendingAction = null;
        state.session.data = null;
        state.session.error = action.payload;
      })
      .addCase(fetchCurrentUser.pending, (state) => {
        state.user.loading = true;
        state.user.data = null;
        state.user.error = null;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.user.loading = false;
        state.user.data = action.payload;
        state.user.error = null;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.user.loading = false;
        state.user.data = null;
        state.user.error = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user.loading = false;
        state.user.data = action.payload;
        state.user.error = null;
      })
      .addCase(updateUser.pending, (state) => {
        state.user.loading = true;
        state.user.error = null;
      })
      .addCase(updateUserPassword.pending, (state, action) => {
        state.user.loading = true;
        state.user.error = null;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.user.loading = false;
        state.user.error = action.payload;
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        state.user.loading = false;
        state.user.data = { ...state.user.data, passwordChanged: true };
        state.user.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.user.error = action.payload;
      })
      .addCase(refreshToken.pending, (state) => {
        state.user.loading = false;
        state.user.error = null;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.user.loading = false;
        state.user.error = null;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.user.loading = false;
        state.user.error = action.payload;
      });
  }
});

export const { renewSession, markTokenAsExpired, resetPasswordChangeStatus } =
  userSlice.actions;

export default userSlice.reducer;
