import BaseButton from 'components/base/BaseButton';
import BaseInput, { BaseInputGroup } from 'components/base/BaseInput';
import BaseSelect from 'components/base/BaseSelect';
import { CLUSTER_TYPE_CHOICES } from 'shared/Constants';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Checkbox } from '@mui/material';

import {
  createCluster,
  listClusters,
  updateCluster
} from 'redux/slices/clusterSlice';
import { listDataModels } from 'redux/slices/dataModelSlice';

import styles from './ClusterForm.module.scss';
import baseStyles from 'components/base/BaseStyles.module.scss';

export default function ClusterForm(props) {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [size, setSize] = useState('');
  const [description, setDescription] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [csvSettings, setCsvSettings] = useState({});
  const [engineSettings, setEngineSettings] = useState({
    useQueryCache: false,
    enableConfigService: false,
    enableDataService: false,
    enableQueryService: false,
    enableDataModelService: false,
    enableDatabaseImportService: false,
    requireSecurityRole: false
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [dataModel, setDataModel] = useState([]);

  const titleVerb = id ? 'Edit' : 'Create';
  const actionVerb = id ? 'Update' : 'Create';

  const currentEnvironment = useSelector(
    (state) => state.environmentSlice.currentEnvironment
  );
  const clusters = useSelector((state) => state.clusterSlice.clusters);

  const dataModels = useSelector(
    (state) => state.dataModelSlice.dataModels.data || []
  );

  useEffect(() => {
    dispatch(listDataModels());
  }, [dispatch]);

  useEffect(() => {
    let cluster = clusters.data.find((cluster) => cluster.id === id);

    if (cluster) {
      setName(cluster.name);
      setType(cluster.cluster_type);
      setSize(cluster.cluster_size);
      setDescription(cluster.description);
      setCsvSettings(cluster.csv_settings);
      setEngineSettings(cluster.engine_services);
      setDataModel(cluster.datamodels.map((dm) => dm.datamodel_id));
    } else {
      setCsvSettings({
        quote: '"',
        encoding: 'UTF-8',
        addHeader: true,
        delimiter: ',',
        preventInjectionRegex: '^[=+-@\'"]',
        nullValue: '(null)'
      });

      setEngineSettings({
        useQueryCache: true,
        enableConfigService: true,
        enableDataService: true,
        enableQueryService: true,
        enableDataModelService: true,
        enableDatabaseImportService: true,
        requireSecurityRole: false
      });
    }
  }, [id]); // eslint-disable-line

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidationErrors({});

    let validationErrors = {};

    if (name.length === 0) {
      validationErrors = { ...validationErrors, name: 'should be present' };
    }

    if (type.length === 0) {
      validationErrors = { ...validationErrors, type: 'should be present' };
    }

    if (size.length === 0) {
      validationErrors = { ...validationErrors, size: 'should be present' };
    }

    if (csvSettings?.quote.length === 0) {
      validationErrors = {
        ...validationErrors,
        csvSettings: {
          ...validationErrors.csvSettings,
          quote: 'should be present'
        }
      };
    }

    if (csvSettings?.encoding.length === 0) {
      validationErrors = {
        ...validationErrors,
        csvSettings: {
          ...validationErrors.csvSettings,
          encoding: 'should be present'
        }
      };
    }

    if (csvSettings?.delimiter.length === 0) {
      validationErrors = {
        ...validationErrors,
        csvSettings: {
          ...validationErrors.csvSettings,
          delimiter: 'should be present'
        }
      };
    }

    if (csvSettings?.preventInjectionRegex.length === 0) {
      validationErrors = {
        ...validationErrors,
        csvSettings: {
          ...validationErrors.csvSettings,
          preventInjectionRegex: 'should be present'
        }
      };
    }

    if (csvSettings?.nullValue.length === 0) {
      validationErrors = {
        ...validationErrors,
        csvSettings: {
          ...validationErrors.csvSettings,
          nullValue: 'should be present'
        }
      };
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors);
      return;
    }

    if (id) {
      dispatch(
        updateCluster({
          id,
          name,
          description,
          type,
          size,
          csvSettings,
          engineSettings,
          dataModelIds: dataModel.map((id) => {
            return { datamodel_id: id };
          })
        })
      ).then(() => {
        actionCallback();
      });
    } else {
      dispatch(
        createCluster({
          name,
          description,
          type,
          size,
          environmentId: currentEnvironment.id,
          csvSettings,
          engineSettings,
          dataModelIds: dataModel.map((id) => {
            return { datamodel_id: id };
          })
        })
      ).then(() => {
        actionCallback();
      });
    }
  };

  const actionCallback = () => {
    dispatch(listClusters({ environmentId: currentEnvironment.id }));
    if (props.onAction) {
      props.onAction();
    }
    navigate('/dashboard/clusters');
  };

  return (
    <Box style={{ position: 'relative', paddingBottom: 82 }}>
      <Box className={styles.header}>
        <Box className={styles.title}>{titleVerb} cluster</Box>
      </Box>
      <Box mt={2}>
        <strong>1.</strong> Define your cluster to deploy it in the{' '}
        <strong>{currentEnvironment?.name || 'undefined'}</strong> environment.
      </Box>
      <Box style={{ marginTop: 20, maxWidth: 550 }}>
        <form onSubmit={handleSubmit}>
          <BaseInputGroup>
            <BaseInput
              name="name"
              placeholder="Name"
              autoComplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
              validationError={validationErrors.name}
              dynamicPlaceholder
            />
            <BaseSelect
              placeholder="Type"
              autoComplete="off"
              value={type}
              items={CLUSTER_TYPE_CHOICES}
              onChange={(e) => setType(e.target.value)}
              validationError={validationErrors.type}
              dynamicPlaceholder
            />
            <BaseInput
              type="number"
              placeholder="Size (max 10)"
              autoComplete="off"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              validationError={validationErrors.size}
              dynamicPlaceholder
            />
            <BaseInput
              placeholder="Description (optional)"
              autoComplete="off"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              validationError={validationErrors.description}
              dynamicPlaceholder
            />
          </BaseInputGroup>
          <Box className={styles.divider} />
          <Box style={{ marginBottom: 20 }}>
            <strong>2.</strong> Select data models to use with this cluster.
          </Box>
          <BaseInputGroup>
            <BaseSelect
              placeholder="Data model"
              autoComplete="off"
              value={dataModel}
              items={dataModels.map((dataModel) => ({
                name: dataModel.name,
                value: dataModel.id
              }))}
              onChange={(e) =>
                setDataModel(
                  typeof e.target.value === 'string'
                    ? e.target.value.split(',')
                    : e.target.value
                )
              }
              validationError={validationErrors.description}
              dynamicPlaceholder
              multiple
            />
          </BaseInputGroup>
          <Box className={styles.divider} />
          <Box style={{ marginBottom: 20 }}>
            <strong>3.</strong> Configure CSV settings (or keep the defaults).
          </Box>
          <BaseInputGroup>
            <BaseInput
              placeholder="Quote"
              autoComplete="off"
              value={csvSettings?.quote}
              onChange={(e) =>
                setCsvSettings({ ...csvSettings, quote: e.target.value })
              }
              validationError={validationErrors.csvSettings?.quote}
              dynamicPlaceholder
            />
            <BaseInput
              placeholder="Encoding"
              autoComplete="off"
              value={csvSettings?.encoding}
              onChange={(e) =>
                setCsvSettings({ ...csvSettings, encoding: e.target.value })
              }
              validationError={validationErrors.csvSettings?.encoding}
              dynamicPlaceholder
            />
            <BaseInput
              placeholder="Delimiter"
              autoComplete="off"
              value={csvSettings?.delimiter}
              onChange={(e) =>
                setCsvSettings({ ...csvSettings, delimiter: e.target.value })
              }
              validationError={validationErrors.csvSettings?.delimiter}
              dynamicPlaceholder
            />
            <BaseInput
              placeholder="Prevent Injection Regex"
              autoComplete="off"
              value={csvSettings?.preventInjectionRegex}
              onChange={(e) =>
                setCsvSettings({
                  ...csvSettings,
                  preventInjectionRegex: e.target.value
                })
              }
              validationError={
                validationErrors.csvSettings?.preventInjectionRegex
              }
              dynamicPlaceholder
            />
            <BaseInput
              placeholder="Null Value"
              autoComplete="off"
              value={csvSettings?.nullValue}
              onChange={(e) =>
                setCsvSettings({ ...csvSettings, nullValue: e.target.value })
              }
              validationError={validationErrors.csvSettings?.nullValue}
              dynamicPlaceholder
            />
          </BaseInputGroup>
          <Box className={styles.divider} />
          <Box style={{ marginBottom: 20 }}>
            <strong>4.</strong> Select the engine services to enable (or keep
            the defaults).
          </Box>
          <BaseInputGroup>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <strong>Query Cache</strong>
              <Box>
                <label
                  htmlFor="js-query-cache-checkbox"
                  style={{ fontSize: 14, marginRight: 5 }}
                >
                  Enable
                </label>
                <Checkbox
                  id="js-query-cache-checkbox"
                  style={{ color: '#343434', marginLeft: -8 }}
                  checked={engineSettings?.useQueryCache}
                  onChange={(e) =>
                    setEngineSettings({
                      ...engineSettings,
                      useQueryCache: e.target.checked
                    })
                  }
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <strong>Config Service</strong>
              <Box>
                <label
                  htmlFor="js-config-service-checkbox"
                  style={{ fontSize: 14, marginRight: 5 }}
                >
                  Enable
                </label>
                <Checkbox
                  id="js-config-service-checkbox"
                  style={{ color: '#343434', marginLeft: -8 }}
                  checked={engineSettings?.enableConfigService}
                  onChange={(e) =>
                    setEngineSettings({
                      ...engineSettings,
                      enableConfigService: e.target.checked
                    })
                  }
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <strong>Data Service</strong>
              <Box>
                <label
                  htmlFor="js-data-service-checkbox"
                  style={{ fontSize: 14, marginRight: 5 }}
                >
                  Enable
                </label>
                <Checkbox
                  id="js-data-service-checkbox"
                  style={{ color: '#343434', marginLeft: -8 }}
                  checked={engineSettings?.enableDataService}
                  onChange={(e) =>
                    setEngineSettings({
                      ...engineSettings,
                      enableDataService: e.target.checked
                    })
                  }
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <strong>Query Service</strong>
              <Box>
                <label
                  htmlFor="js-query-service-checkbox"
                  style={{ fontSize: 14, marginRight: 5 }}
                >
                  Enable
                </label>
                <Checkbox
                  id="js-query-service-checkbox"
                  style={{ color: '#343434', marginLeft: -8 }}
                  checked={engineSettings?.enableQueryService}
                  onChange={(e) =>
                    setEngineSettings({
                      ...engineSettings,
                      enableQueryService: e.target.checked
                    })
                  }
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <strong>Data Model Service</strong>
              <Box>
                <label
                  htmlFor="js-datamodel-checkbox"
                  style={{ fontSize: 14, marginRight: 5 }}
                >
                  Enable
                </label>
                <Checkbox
                  id="js-datamodel-checkbox"
                  style={{ color: '#343434', marginLeft: -8 }}
                  checked={engineSettings?.enableDataModelService}
                  onChange={(e) =>
                    setEngineSettings({
                      ...engineSettings,
                      enableDataModelService: e.target.checked
                    })
                  }
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <strong>Database Import Service</strong>
              <Box>
                <label
                  htmlFor="js-database-import-checkbox"
                  style={{ fontSize: 14, marginRight: 5 }}
                >
                  Enable
                </label>
                <Checkbox
                  id="js-database-import-checkbox"
                  style={{ color: '#343434', marginLeft: -8 }}
                  checked={engineSettings?.enableDatabaseImportService}
                  onChange={(e) =>
                    setEngineSettings({
                      ...engineSettings,
                      enableDatabaseImportService: e.target.checked
                    })
                  }
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <strong>Security Role</strong>
              <Box>
                <label
                  htmlFor="js-security-role-checkbox"
                  style={{ fontSize: 14, marginRight: 5 }}
                >
                  Require
                </label>
                <Checkbox
                  id="js-security-role-checkbox"
                  style={{ color: '#343434', marginLeft: -8 }}
                  checked={engineSettings?.requireSecurityRole}
                  onChange={(e) =>
                    setEngineSettings({
                      ...engineSettings,
                      requireSecurityRole: e.target.checked
                    })
                  }
                />
              </Box>
            </Box>
          </BaseInputGroup>
          <Box className={baseStyles.formBottomBar}>
            <Box className={baseStyles.formBottomBarContent}>
              <Button
                className={baseStyles.underlinedButton}
                onClick={() => navigate('/dashboard/clusters')}
              >
                Cancel
              </Button>
              <BaseButton
                type="submit"
                title={actionVerb}
                style={{ marginTop: 0, maxWidth: 200 }}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
