import BaseBackdrop from 'components/base/BaseBackdrop';
import BaseButton from 'components/base/BaseButton';
import BaseCard from 'components/base/BaseCard';
import BaseInput from 'components/base/BaseInput';

import React, { useState } from 'react';

import styles from './DeletePage.module.scss';

export default function DeletePage(props) {
  const [name, setName] = useState('');

  const handleBackdropClick = (e) => {
    props.onCancel();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onAction();
  };

  return (
    <BaseBackdrop onClose={handleBackdropClick}>
      <BaseCard
        title={`Delete ${props.entityType}`}
        subtitle={`To confirm deletion, enter <strong>${props.entityName}</strong> in the field.`}
      >
        <form onSubmit={handleSubmit}>
          <BaseInput
            autoFocus={true}
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <BaseButton
            type="submit"
            title="Delete"
            className={styles.button}
            style={{ marginTop: 30 }}
            disabled={name !== props.entityName}
          />
        </form>
      </BaseCard>
    </BaseBackdrop>
  );
}
