import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'uiReducer',
  initialState: {
    modal: null,
    snackbarQueue: []
  },
  reducers: {
    displayModal: (state, action) => {
      state.modal = action.payload;
    },
    dismissModal: (state) => {
      state.modal = null;
    },
    addSnackbarToQueue: (state, action) => {
      state.snackbarQueue.push(action.payload);
    },
    removeSnackbarFromQueue: (state) => {
      state.snackbarQueue.shift();
    }
  }
});

export const {
  displayModal,
  dismissModal,
  addSnackbarToQueue,
  removeSnackbarFromQueue
} = uiSlice.actions;

export default uiSlice.reducer;
