import { DATABASE_CHOICES, SCHEMA_LOCATION_TYPE } from 'shared/Constants';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Delete, MoreHoriz } from '@mui/icons-material';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';

import styles from './DataModelCard.module.scss';

export default function ClusterCard(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditItemClick = () => {
    setAnchorEl(null);
    navigate(`/dashboard/data_models/${props.data.id}/edit`);
  };

  const handleDeleteItemClick = () => {
    setAnchorEl(null);
    props.onDelete({
      entityType: 'dataModel',
      entityName: props.data.name,
      entityId: props.data.id
    });
  };

  let databaseType = DATABASE_CHOICES.find(
    (item) => item.value === props.data.database_type
  )?.name;

  let schemaLocationType = SCHEMA_LOCATION_TYPE.find(
    (item) => item.value === props.data.schema_location_type
  )?.name;

  return (
    <Box className={styles.card}>
      <Box className={styles.cardTitle}>{props.data.name}</Box>
      <Box className={styles.cardDescription} style={{ paddingBottom: 15 }}>
        <Box className={styles.cardDescriptionRow}>
          <span>Name</span>
          <span>{props.data.name}</span>
        </Box>
        <Box className={styles.cardDescriptionRow}>
          <span>Scope name</span>
          <span>{props.data.scope_name}</span>
        </Box>
        <Box className={styles.cardDescriptionRow}>
          <span>Database type</span>
          <span>{databaseType}</span>
        </Box>
        <Box className={styles.cardDescriptionRow}>
          <span>Schema location type</span>
          <span>{schemaLocationType}</span>
        </Box>
        <Box className={styles.cardDescriptionRow}>
          <span>Schema URL</span>
          <span>{props.data.schema_url}</span>
        </Box>
      </Box>
      <Box className={styles.cardFooter} style={{ paddingTop: 20 }}>
        <Box className={styles.cardFooterItem}>
          <Button className={styles.button} onClick={handleEditItemClick}>
            Edit
          </Button>
          <Button className={styles.buttonSquare} onClick={handleClick}>
            <MoreHoriz style={{ color: '#343434' }} />
          </Button>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem className={styles.menuItem} onClick={handleDeleteItemClick}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText className={styles.menuItemLabel}>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}
