import BaseInput from 'components/base/BaseInput';

import React from 'react';

import { Delete, Upload } from '@mui/icons-material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Fade } from '@mui/material';
import { Box } from '@mui/material';

import styles from './DataModelFileRow.module.scss';
import baseStyles from 'components/base/BaseStyles.module.scss';

export default function DataModelFileRow(props) {
  return (
    <Fade in={true} unmountOnExit>
      <Box>
        <Box className={styles.wrapper} key={props.file.priority}>
          {props.shouldDelete && (
            <Box className={baseStyles.deleteOverlay}>
              This file is staged for deletion.&nbsp;
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  props.onDeleteUndo(props.file.priority);
                }}
              >
                Undo
              </a>
            </Box>
          )}
          <Box className={styles.topRow}>
            <BaseInput
              wrapperStyle={{ flex: 1, paddingRight: 5 }}
              className={baseStyles.titleInput}
              value={props.file.name}
              placeholder="Configuration file name"
              onChange={(e) =>
                props.onNameChange(e.target.value, props.file.priority)
              }
            ></BaseInput>
            {/* <input value={props.file.priority}></input> */}
            <Box
              className={baseStyles.buttonSm}
              onClick={() => props.onUpload(props.file.priority)}
              style={{ marginLeft: 'auto', marginRight: 5 }}
            >
              <Upload style={{ fontSize: 20, marginRight: 8 }} />
              Upload
            </Box>
            <Box
              className={`${baseStyles.buttonSm} ${baseStyles.remove}`}
              onClick={() => props.onRemove(props.file.priority)}
            >
              <Delete style={{ fontSize: 20 }} />
            </Box>
          </Box>
          <textarea
            className={styles.textarea}
            rows="10"
            value={JSON.stringify(props.file.content, null, 2)}
            onChange={(e) =>
              props.onContentChange(e.target.value, props.file.priority)
            }
          />
          <Box className={styles.bottomRow}>
            <Box
              className={styles.bottomButton}
              onClick={props.onPriorityButtonClick}
            >
              Priority — {props.file.priority + 1}
              <Box className={styles.priorityButtons}>
                <Box
                  className={`${styles.priorityButton} ${
                    props.priorityUpButtonDisabled ? styles.disabled : ''
                  }`}
                  onClick={props.onPriorityUp}
                >
                  <KeyboardArrowUp />
                </Box>
                <Box
                  className={`${styles.priorityButton} ${
                    props.priorityDownButtonDisabled ? styles.disabled : ''
                  }`}
                  onClick={props.onPriorityDown}
                >
                  <KeyboardArrowDown />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}
