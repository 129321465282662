import BaseButton from 'components/base/BaseButton';
import BaseInput, { BaseInputGroup } from 'components/base/BaseInput';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import {
  createEnvironment,
  listEnvironments
} from 'redux/slices/environmentSlice';

import styles from './EnvironmentForm.module.scss';
import baseStyles from 'components/base/BaseStyles.module.scss';

export default function EnvironmentForm(props) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const currentOrganizationId = useSelector(
    (state) => state.organizationSlice.currentOrganizationId
  );
  // const environments = useSelector(
  //   (state) => state.environmentSlice.environments
  // );

  const titleVerb = id ? 'Edit' : 'Create';
  const actionVerb = id ? 'Update' : 'Create';

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidationErrors({});

    if (name.length === 0) {
      setValidationErrors((prevState) => ({
        ...prevState,
        name: 'should be present'
      }));

      return;
    }

    dispatch(
      createEnvironment({
        name,
        description,
        organizationId: currentOrganizationId
      })
    ).then(() => {
      dispatch(listEnvironments({ environment: id }));
      navigate('/dashboard/clusters');
    });
  };

  return (
    <Box style={{ position: 'relative', paddingBottom: 82 }}>
      <Box className={styles.header}>
        <Box className={styles.title}>{titleVerb} environment</Box>
      </Box>
      <Box mt={2} style={{ maxWidth: 550 }}>
        {id ? 'Use' : 'Create'} an environment to separate your clusters (e.g.
        dev, staging, prod).
      </Box>
      <Box style={{ marginTop: 20, maxWidth: 550 }}>
        <form onSubmit={handleSubmit}>
          <BaseInputGroup>
            <BaseInput
              placeholder="Name"
              autoComplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
              validationError={validationErrors.name}
            />
            <BaseInput
              placeholder="Description (optional)"
              autoComplete="off"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              validationError={validationErrors.description}
            />
          </BaseInputGroup>
          <Box className={baseStyles.formBottomBar}>
            <Box className={baseStyles.formBottomBarContent}>
              <Button
                className={baseStyles.underlinedButton}
                onClick={() => navigate('/dashboard/clusters')}
              >
                Cancel
              </Button>
              <BaseButton
                type="submit"
                title={actionVerb}
                style={{ marginTop: 0, maxWidth: 200 }}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
